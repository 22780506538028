import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PopularPost from './blog-components/popular-post';
import PostList from './blog-components/post-list';
import Footer from './global-components/footer';
import FooterFixed from "./global-components/footer-fixed";

const News = () => {
    return <div>
        <Navbar/>
        <PageHeader headertitle="Notícias" subheader="Notícias"/>
        <PopularPost/>
        <PostList/>
        <Footer/>
        <FooterFixed />
    </div>
}

export default News

