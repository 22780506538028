import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import footerdata from '../../data/footerdata.json';
import ConsoleHelper from "../../helpers/ConsoleHelper";

class Footer_v1 extends Component {

  state = {
    acceptedTerm: false
  }

  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    if(localStorage.getItem("acceptedTerm")) {
      this.setState({
        acceptedTerm: localStorage.getItem("acceptedTerm")
      });
    }
  }

  acceptTerms() {
    ConsoleHelper(this.state.acceptedTerm)
    this.setState({
      acceptedTerm: true
    });
    localStorage.setItem("acceptedTerm", true)
  }

  render() {

    let publicUrl = window.env.PUBLIC_URL + '/'
    let imgattr = "logo"
    const inlineStyle = {
      backgroundImage: 'url(' + publicUrl + footerdata.footerbg + ')'
    }

    return (
        <footer className="footer-area">
          <div className="container">
            <div className="footer-top">
              <div className="row">
                <div className="col-sm-4">
                  <a className="footer-logo" href="#">
                    <img src={publicUrl + "/assets/img/logo.png"} loading="lazy" alt="logo"/>
                    <p>6367J</p>
                    <p>Telefone: <a href="tel:4733111100">(47) 3311-1100</a></p>
                    <p>Endereço: Rua Miguel Matte, 687 Sala 2007
                      Pioneiros - Balneário Camboriú/SC <br/>
                      CEP: 81331-030
                    </p>

                  </a>
                </div>
                <div className="col-sm-8">
                  <div className="footer-social text-sm-right">
                    <span>SIGA-NOS</span>
                    <ul className="social-icon">
                      {footerdata.socialicon.map((item, i) =>
                          <li key={i}>
                            <a href={item.url} target={"_blank"}><i className={item.icon}/></a>
                          </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <div className="widget widget_nav_menu">
                    <h4 className="widget-title">{footerdata.popularserces.title}</h4>
                    <ul>
                      {footerdata.popularserces.links.map((item, i) =>
                          <li className="readeal-top" key={i}><Link to={item.url}>{item.title}</Link></li>
                      )}

                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="widget widget_nav_menu">
                    <h4 className="widget-title">{footerdata.quicklink.title}</h4>
                    <ul>

                      <li><a href="https://portalbc.online/sobre">Sobre</a></li>
                      <li><a href="https://portalbc.online/contato">Contato</a></li>
                      <li><Link to="/politica-privacidade">Política de Privacidade</Link></li>
                      <li><Link to="/parceiros">Nossos Parceiros</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <form className="widget widget-subscribe" method="GET">
                    <div className="rld-single-input">
                      <input type="text" name="name" placeholder="Nome Completo"/>
                    </div>
                    <div className="rld-single-input">
                      <input type="text" name="email" placeholder="Seu@email.com"/>
                    </div>
                    <button className="btn btn-yellow w-100">Inscreva-se</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="copy-right text-center" dangerouslySetInnerHTML={{__html: footerdata.copyrighttext}}></div>
          </div>
          {!this.state.acceptedTerm &&
            <div className="controledeprivacidade">
              <div className="container">
                <div className="row caixaprivacidade">
                  <div className="row ml-4">
                    <div className="col-lg-10 col-sm-12 col-xs-12">
                      <p className="titcontrolepriv flow-text"><b>PortalBC</b> e os Cookies - Nosso
                        site usa cookies para melhorar a sua experiência de
                        navegação. Confira a <b> <Link to="/politica-privacidade">Política de Privacidade</Link></b></p>
                    </div>
                    <div className="col-lg-1 col-sm-12 col-xs-12">
                      <a className="btn btn-yellow" onClick={event => this.acceptTerms()}>ACEITO</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </footer>
    )
  }
}


export default Footer_v1
