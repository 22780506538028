import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import LogAction from "../../helpers/LogAction";

class Navbar extends Component {

    render() {
        return (
            <div>
                <div className="navbar-area">
                    <nav className="navbar navbar-area navbar-expand-lg">
                        <div className="container nav-container">
                            <div className="responsive-mobile-menu">
                                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse"
                                        data-target="#realdeal_main_menu" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="icon-left"/>
                                    <span className="icon-right"/>
                                </button>
                            </div>
                            <div className="logo readeal-top">
                                <Link to="/"><img src={"/assets/img/logo.png"} alt="logo" loading="lazy"/></Link>
                            </div>
                            {/*<div className="nav-right-part nav-right-part-mobile">*/}
                            {/*    <Link className="btn btn-yellow" to="/add-property">ADICIONAR IMÓVEL <span*/}
                            {/*        className="right"><i className="la la-plus"/></span></Link>*/}
                            {/*</div>*/}
                            <div className="collapse navbar-collapse" id="realdeal_main_menu">
                                <ul className="navbar-nav menu-open readeal-top">
                                    <li><Link to="/" className="underline-gray">Início</Link></li>
                                    <li><Link to="/search-list" className="underline-gray">Comprar</Link></li>
                                    <li>
                                        <a href={'https://portalbc.online/imoveis-recentes'} onClick={e => LogAction('menu-imoveis-recentes', document.location.hash.replace('#/', ''))} className="underline-gray">Imóveis Recentes</a>
                                    </li>
                                    <li>
                                        <a href={'https://portalbc.online/destaque'} onClick={e => LogAction('menu-destaque', document.location.hash.replace('#/', ''))} className="underline-gray">Destaque</a>
                                    </li>
                                    {/*<li className="menu-item-has-children">*/}
                                        {/*<a href="#" className="underline-gray">Imóveis</a>*/}
                                        {/*<ul className="sub-menu">*/}
                                            {/*<li><Link to="/search-list">Comprar</Link></li>*/}
                                            {/*<li><Link to="/properties-by-city">Imóveis por Localização</Link></li>*/}
                                            {/*<li><Link to="/recent-properties">Imóveis Recentes</Link></li>*/}
                                        {/*</ul>*/}
                                    {/*</li>*/}
                                    <li><a  href={'https://portalbc.online/sobre'}className="underline-gray">Sobre</a></li>
                                    <li><Link to="/noticias" className="underline-gray">Notícias</Link></li>
                                    {/*<li><Link to="/contato">Contato</Link></li>*/}
                                </ul>
                            </div>
                            {/*<div className="nav-right-part nav-right-part-desktop readeal-top">*/}
                            {/*    <Link className="btn btn-yellow" to="/add-property">ADICIONAR IMÓVEL<span*/}
                            {/*        className="right"><i className="la la-plus"/></span></Link>*/}
                            {/*</div>*/}
                        </div>
                    </nav>
                </div>
            </div>
        )
    }
}


export default Navbar
