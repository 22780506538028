import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import axios from 'axios';

class TeamV2 extends Component {

    state = {
        team: []
    };

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/v1/realtor?no_pagination=true')
            .then(res => {
                const team = res.data;
                this.setState({team:team})
            })
    }

    render() {
        return <div className="team-area pd-top-70">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="title">Consultores</h2>
                </div>
                <div className="row">
                    {this.state.team.map((item, i) =>
                        <div key={i} className="col-lg-4 col-sm-6">
                            <div className="single-team">
                                <div className="thumb">
                                    <img src={item.avatar} alt="team" loading="lazy"/>
                                </div>
                                <div className="team-details">
                                    <h4>{item.name}</h4>
                                  <ul style={{float: 'left'}}>
                                    {item.realtor_doc_number &&
                                    <div>
                                      <span>{item.realtor_doc_number} </span>
                                      <br/>
                                    </div>
                                    }
                                    {item.acting_cities &&
                                    <div>
                                      <span>{item.acting_cities}</span>
                                      <br/>
                                    </div>
                                    }
                                    {item.phone &&
                                    <span>{item.phone}</span>
                                    }
                                  </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    }
}

export default TeamV2
