import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import { Link } from 'react-router-dom';
import LogAction from "../../helpers/LogAction";

class Explore extends Component {

    state = {
        neighborhoods: []
    };


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.explore

    return <div className="explore-area pd-top-85">
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title">Explore por localização</h2>
        </div>
        <div className="row">
        { data.items.map( ( item, i )=>
            <div key={ i } className="col-lg-2 col-sm-6 col-6">
            <div className="single-explore">
              <div className="thumb">
                <img src={ publicUrl+item.image } alt="explore" loading="lazy"/>
                <Link to={item.url} target={"_blank"} onClick={e => LogAction('explore_' + item.city, 'home')}><i className="fa fa-paper-plane" /></Link>
              </div>
              <div className="details readeal-top">
                <h4><Link to={item.url} target={"_blank"} onClick={e => LogAction('explore_' + item.city, 'home')}>{ item.city }</Link></h4>
                {/*<ul className="list">*/}
                  {/*<li><img src={publicUrl +"/assets/img/icons/1.png"} alt="icona" />Listings: { item.lisgintnumber }</li>*/}
                  {/*<li><i className="fa fa-usd" />Price: ${ item.price }</li>*/}
                {/*</ul>*/}
              </div>
            </div>
          </div>
         ) }

        </div>
      </div>
    </div>


        }
}

export default Explore
