import React, {Component, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import axios from "axios";
import ConsoleHelper from "../helpers/ConsoleHelper";
import InputMask from "react-input-mask";
import MetaTags from 'react-meta-tags';
import ReCAPTCHA from "react-google-recaptcha";
import CurrencyInput from 'react-currency-input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker,{registerLocale} from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import ImageUploading from 'react-images-uploading';
registerLocale('ptBR', ptBR);
const recaptchaRef = React.createRef();

class Agency extends Component {

    componentDidMount() {

        const $ = window.$;

        $("input").focus(function() {
            var error_input = $(this);
            var line_height = $(".label-danger").height() - 200;

            $('html, body').animate({
                scrollTop: error_input.offset().top + line_height
            }, 900);
        });


    }
    state = {
        messageSent: false,
        form: {},
        errors: {},
        sending: false,
        images: [],
        images_upload: 0,
        documents_upload: 0,
    }

    sendAgency = async e => {
        e.preventDefault();
        this.setState({sending: true, errors: {}});

        let valid = await this.validateForm();

        if (!valid) {
            this.setState({sending: false});
            return;
        }
        this.setState({
            images_upload: this.state.images.length
        })
        for (let image of this.state.images) {
            await this.upload(image.data_url, image.file.name, 'images');
        }
        this.setState({
            documents_upload: this.state.documents ? this.state.documents.length : 0
        })
        if (this.state.documents && this.state.documents.length > 0) {
            for (let document of this.state.documents) {
                await this.upload(document, document.name, 'documents');
            }
        }

        let data = this.state.form;
        data.recaptcha = recaptchaRef.current.getValue();
        axios.post(process.env.REACT_APP_API_URL + '/v1/agency', data)
            .then(res => {
                this.setState({sending: false});
                const success = res.data;
                ConsoleHelper(success)
                this.setState({messageSent: true})
            })
            .catch((error) => {
                this.setState({sending: false});
                ConsoleHelper(error, error.response)
                const data = error.response.data;
                if (typeof data.errors != 'undefined') {
                    this.setState({errors: data.errors})
                }
            });
    }

    handleInputChange = e => {
        this.setState({
            form: {...this.state.form, [e.target.name]: e.target.value}
        });
    }

    allowDates = date => {
        const day = date.getDay();
        return day == 3 || day == 5;
    }

    setDate = date => {
        this.setState({
            form: {...this.state.form, date_photos:date}
        })
    }
    onChangeImage = async (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        this.setState({
            images: imageList
        })
        // setImages(imageList);
    }

    upload = (image, fileName, type) => {
        return new Promise(
            resolve => {
                // axios.post(process.env.REACT_APP_API_URL + '/v1/agencyUpload', {
                let data = {
                    file: image,
                    name: fileName,
                    type: type
                };
                if (type == 'documents') {
                    data = new FormData();
                    data.append('file', image);
                    data.append('name', fileName);
                    data.append('type', type);
                }
                axios.post(process.env.REACT_APP_API_URL + '/v1/agencyUpload', data)
                    .then(res => {
                        console.log('res', res)
                        if (type === 'images') {
                            let images = this.state.form.images;
                            if (!images) {
                                images = [];
                            }
                            images.push(res.data.file);
                            const count_uploads = this.state.images_upload - 1;
                            this.setState({
                                form: {...this.state.form, images:images},
                                images_upload: count_uploads,
                            })
                        } else {
                            let documents = this.state.form.documents;
                            if (!documents) {
                                documents = [];
                            }
                            documents.push(res.data.file);
                            const count_uploads = this.state.documents_upload - 1;
                            this.setState({
                                form: {...this.state.form, documents:documents},
                                documents_upload: count_uploads,
                            })
                        }
                        resolve(true);
                    })
                    .catch((error) => {
                        resolve(true);
                        if(type === 'images') {
                            const count_uploads = this.state.images_upload - 1;
                            this.setState({
                                images_upload: count_uploads,
                            })
                        } else {
                            const count_uploads = this.state.documents_upload - 1;
                            this.setState({
                                documents_upload: count_uploads,
                            })
                        }
                        console.log(error)
                    });
            }
        )

    }

    validateForm = () => {
        return new Promise(
            resolve => {
                let data = this.state.form;
                data.recaptcha = recaptchaRef.current.getValue();
                axios.post(process.env.REACT_APP_API_URL + '/v1/agencyValidation', data)
                    .then(() => resolve(true))
                    .catch((error) => {
                        this.setState({sending: false});
                        ConsoleHelper(error, error.response)
                        const data = error.response.data;
                        if (typeof data.errors != 'undefined') {
                            this.setState({errors: data.errors})
                        }
                        resolve(false)
                    });
            }
        );
    }

    addDocuments = (e) => {
        if (Array.from(e.target.files).length > 10) {
            e.preventDefault();
            alert(`Não é permitido adicionar mais de 10 arquivos`);
            document.getElementById('property_documents').value="";
            return false;
        }
        console.log(e.target.files)
        this.setState({
            documents: e.target.files
        })
    }

    render() {

        // Fix autofocus issues with CurrencyInput
        // on iOS it will still auto focus even if autoFocus=false
        // see https://github.com/jsillitoe/react-currency-input/issues/90
        let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
        CurrencyInput.prototype.componentDidMount = function() {
            if(!this.props.autoFocus) {
                let setSelectionRange_super = this.theInput.setSelectionRange;
                this.theInput.setSelectionRange = () => {};
                componentDidMount_super.call(this, ...arguments);
                this.theInput.setSelectionRange = setSelectionRange_super;
            }
            else {
                componentDidMount_super.call(this, ...arguments);
            }
        };
        let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
        CurrencyInput.prototype.componentDidUpdate = function() {
            if(!this.props.autoFocus) {
                let setSelectionRange_super = this.theInput.setSelectionRange;
                this.theInput.setSelectionRange = () => {};
                componentDidUpdate_super.call(this, ...arguments);
                this.theInput.setSelectionRange = setSelectionRange_super;
            }
            else {
                componentDidMount_super.call(this, ...arguments);
            }
        };
        return <div>
            <MetaTags>
                <title>Contato</title>
                <link rel="canonical" href={document.location.href}/>
                <meta name="description" content="Oferecemos diversos canais de comunicação. Converse com a gente sempre que precisar."/>
                <meta property="og:title" content="Quero vender"/>
                <meta property="og:image" content="/assets/img/logo.png" content="image/jpeg"/>
                <meta property="og:url" content={document.location.href} />
                <meta property="og:site_name" content="Portal BC" />
            </MetaTags>
            <Navbar/>
            <PageHeader headertitle="Contato"/>
            <div className="contact-area pd-top-100 pd-bottom-65">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {this.state.messageSent &&
                                <p>Mensagem enviada com sucesso! <br/> Em breve o corretor agenciador entrará em contato. Obrigado</p>
                            }
                            {!this.state.messageSent &&
                                <form onSubmit={this.sendAgency} className="contact-form-wrap contact-form-bg">
                                    <h4>Quer vender seu imóvel? Fale conosco!</h4>
                                    <p>Somente trabalhamos com a venda de imóveis nas cidades: Balneário Camboriú, Praia Brava, Itapema, Itajaí, Porto Alegre</p>
                                    <div className="rld-single-input">
                                        <label>Nome do Proprietário*</label>
                                        <input required type="text" placeholder="Nome Completo" onChange={this.handleInputChange} value={this.state.form.owner_name} id="owner_name" name="owner_name"/>
                                        { this.state.errors.owner_name &&
                                            <div className="label label-danger">{this.state.errors.owner_name}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Celular do Proprietário*</label>
                                        <InputMask required {...this.props} onChange={this.handleInputChange}
                                                   value={this.state.form.owner_phone} name="owner_phone"
                                                   id="owner_phone" type="tel" placeholder="(00)00000-0000"
                                                   mask="(99)99999-9999" maskChar=" "/>
                                        { this.state.errors.owner_phone &&
                                            <div className="label label-danger">{this.state.errors.owner_phone}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>E-mail de Proprietário*</label>
                                        <input required type="email" placeholder="E-mail" onChange={this.handleInputChange} value={this.state.form.owner_email} id="owner_email" name="owner_email"/>
                                        { this.state.errors.owner_email &&
                                            <div className="label label-danger">{this.state.errors.owner_email}</div>
                                        }
                                    </div><div className="rld-single-input">
                                    <label>Nome do Responsável Pelas Visitas</label>
                                    <input type="text" placeholder="Nome Completo" onChange={this.handleInputChange} value={this.state.form.responsible_name} id="responsible_name" name="responsible_name"/>
                                    { this.state.errors.responsible_name &&
                                        <div className="label label-danger">{this.state.errors.responsible_name}</div>
                                    }
                                </div>
                                    <div className="rld-single-input"  hidden={!this.state.form.responsible_name}>
                                        <label>Celular do Responsável Pelas Visitas*</label>
                                        <InputMask {...this.props} onChange={this.handleInputChange}
                                                   value={this.state.form.responsible_phone} name="responsible_phone"
                                                   id="responsible_phone" type="tel" placeholder="(00)00000-0000"
                                                   mask="(99)99999-9999" maskChar=" "/>
                                        { this.state.errors.responsible_phone &&
                                            <div className="label label-danger">{this.state.errors.responsible_phone}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input" hidden={!this.state.form.responsible_name}>
                                        <label>E-mail do Responsável Pelas Visitas</label>
                                        <input type="email" placeholder="E-mail" onChange={this.handleInputChange} value={this.state.form.responsible_email} id="responsible_email" name="responsible_email"/>
                                        { this.state.errors.responsible_email &&
                                            <div className="label label-danger">{this.state.errors.responsible_email}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Nome do Prédio</label>
                                        <input type="text" placeholder="Nome do Prédio" onChange={this.handleInputChange} value={this.state.form.property_building_name} id="property_building_name" name="property_building_name"/>
                                        { this.state.errors.property_building_name &&
                                            <div className="label label-danger">{this.state.errors.property_building_name}</div>
                                        }
                                    </div>
                                    <div className="rld-single-select">
                                        <label>Quitado: </label>
                                        <select className="form-control" value={this.state.form.property_paid_off}
                                                onChange={this.handleInputChange}
                                                name="property_paid_off">
                                            <option value="">Selecione uma opção</option>
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                        {this.state.errors.property_paid_off &&
                                            <div className="label label-danger">{this.state.errors.property_paid_off}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input" hidden={this.state.form.property_paid_off != '0'}>
                                        <label>Saldo Devedor</label>
                                        <CurrencyInput type="tel"
                                                       onChangeEvent={this.handleInputChange}
                                                       value={this.state.form.debit_balance} id="debit_balance"
                                                       name="debit_balance"
                                                       decimalSeparator="," thousandSeparator="."/>
                                        { this.state.errors.debit_balance &&
                                            <div className="label label-danger">{this.state.errors.debit_balance}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Tipo de Imóvel </label>
                                        <input type="text"
                                               placeholder="casa, casa em condomínio, apartamento, apto diferenciado, cobertura, duplex, triplex, Penthouse, sala comercial, terreno, prédio, lote, área)"
                                               onChange={this.handleInputChange} value={this.state.form.property_type}
                                               id="property_type" name="property_type"/>
                                        {this.state.errors.property_type &&
                                            <div className="label label-danger">{this.state.errors.property_type}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Valor do Condomínio</label>
                                        <CurrencyInput type="tel"
                                                       onChangeEvent={this.handleInputChange}
                                                       value={this.state.form.property_condo_price} id="property_condo_price"
                                                       name="property_condo_price"
                                                       decimalSeparator="," thousandSeparator="."
                                                       min="0"
                                                       maxLength="10"
                                        />
                                        { this.state.errors.property_condo_price &&
                                            <div className="label label-danger">{this.state.errors.property_condo_price}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Valor do IPTU</label>
                                        <CurrencyInput type="tel"
                                                       onChangeEvent={this.handleInputChange}
                                                       value={this.state.form.property_tax_price} id="property_tax_price"
                                                       name="property_tax_price"
                                                       decimalSeparator="," thousandSeparator="."
                                                       min="0"
                                                       maxLength="12"/>
                                        { this.state.errors.property_tax_price &&
                                            <div className="label label-danger">{this.state.errors.property_tax_price}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Vagas</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_parking_lots}
                                               id="property_parking_lots" name="property_parking_lots"
                                               max="100"
                                               min="0"
                                               onWheel={(e) => e.target.blur()}
                                        />
                                        {this.state.errors.property_parking_lots &&
                                            <div className="label label-danger">{this.state.errors.property_parking_lots}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Lavabo</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_washrooms}
                                               id="property_washrooms" name="property_washrooms"
                                               max="10"
                                               min="0"
                                               onWheel={(e) => e.target.blur()}/>
                                        {this.state.errors.property_washrooms &&
                                            <div className="label label-danger">{this.state.errors.property_washrooms}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Banho Social</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_social_baths}
                                               id="property_social_baths" name="property_social_baths"
                                               max="15"
                                               min="0"
                                               onWheel={(e) => e.target.blur()}/>
                                        {this.state.errors.property_social_baths &&
                                            <div className="label label-danger">{this.state.errors.property_social_baths}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Dormitórios</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_bedrooms}
                                               id="property_bedrooms" name="property_bedrooms"
                                               max="100"
                                               min="0"
                                               onWheel={(e) => e.target.blur()}/>
                                        {this.state.errors.property_bedrooms &&
                                            <div className="label label-danger">{this.state.errors.property_bedrooms}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Suítes</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_suites}
                                               id="property_suites" name="property_suites"
                                               max="100"
                                               min="0"
                                               onWheel={(e) => e.target.blur()}/>
                                        {this.state.errors.property_suites &&
                                            <div className="label label-danger">{this.state.errors.property_suites}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Demi-Suítes</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_demi_suites}
                                               id="property_demi_suites" name="property_demi_suites"
                                               max="100"
                                               min="0"
                                               onWheel={(e) => e.target.blur()}/>
                                        {this.state.errors.property_demi_suites &&
                                            <div className="label label-danger">{this.state.errors.property_demi_suites}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Área Útil</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_useful_area}
                                               id="property_useful_area" name="property_useful_area"
                                               max="5000"
                                               min="0"
                                               onWheel={(e) => e.target.blur()}/>
                                        {this.state.errors.property_useful_area &&
                                            <div className="label label-danger">{this.state.errors.property_useful_area}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Ano de Construção</label>
                                        <input type="number"
                                               onChange={this.handleInputChange} value={this.state.form.property_construction_year}
                                               id="property_construction_year" name="property_construction_year"
                                               onWheel={(e) => e.target.blur()}
                                        min="1900" max={(new Date()).getFullYear() + 3}/>
                                        {this.state.errors.property_construction_year &&
                                            <div className="label label-danger">{this.state.errors.property_construction_year}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Valor Pretendido</label>
                                        <CurrencyInput type="tel"
                                                       onChangeEvent={this.handleInputChange}
                                                       value={this.state.form.property_intended_value} id="property_intended_value"
                                                       name="property_intended_value"
                                                       decimalSeparator="," thousandSeparator="."/>
                                        { this.state.errors.property_intended_value &&
                                            <div className="label label-danger">{this.state.errors.property_intended_value}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input">
                                        <label>Descrição</label>
                                        <textarea rows={10} placeholder="Descrição" defaultValue={""} onChange={this.handleInputChange} value={this.state.form.property_description} id="property_description" name="property_description"/>
                                        { this.state.errors.property_description &&
                                            <div className="label label-danger">{this.state.errors.property_description}</div>
                                        }
                                    </div>
                                    <div className="rld-single-select">
                                        <label>Agendamento Fotos</label>
                                        <select className="form-control" value={this.state.form.scheduling_photos}
                                                onChange={this.handleInputChange}
                                                name="scheduling_photos">
                                            <option value="">Selecione uma opção</option>
                                            <option value="1">Sim</option>
                                            <option value="0">Não</option>
                                        </select>
                                        {this.state.errors.scheduling_photos &&
                                            <div className="label label-danger">{this.state.errors.scheduling_photos}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input" hidden={this.state.form.scheduling_photos != '1'}>
                                        <label>Data para Fotos </label>
                                        <DatePicker
                                            name="date_photos"
                                            selected={this.state.form.date_photos}
                                            filterDate={this.allowDates}
                                            locale="ptBR"
                                            minDate={new Date()}
                                            onChange={this.setDate}
                                            dateFormat="dd/MM/yyyy"
                                        />
                                        {this.state.errors.date_photos &&
                                            <div className="label label-danger">{this.state.errors.date_photos}</div>
                                        }
                                    </div>
                                    <div className="rld-single-input" hidden={this.state.form.scheduling_photos != '1'}>
                                        <label>Horário para Fotos </label>
                                        <select className="form-control" value={this.state.form.time_photos}
                                                onChange={this.handleInputChange}
                                                name="time_photos">
                                            <option value="">Selecione uma opção</option>
                                            <option value="08:30">08:30</option>
                                            <option value="10:00">10:00</option>
                                            <option value="11:30">11:30</option>
                                            <option value="14:00">14:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="17:00">17:00</option>
                                        </select>
                                        {this.state.errors.time_photos &&
                                            <div className="label label-danger">{this.state.errors.time_photos}</div>
                                        }
                                    </div>
                                    <ImageUploading
                                        multiple
                                        value={this.state.images}
                                        onChange={this.onChangeImage}
                                        maxNumber={40}
                                        dataURLKey="data_url"
                                        acceptType={['jpeg', 'jpg', 'bmp', 'tiff', 'webp', 'png']}
                                    >
                                        {({
                                              imageList,
                                              onImageUpload,
                                              onImageRemoveAll,
                                              onImageUpdate,
                                              onImageRemove,
                                              isDragging,
                                              dragProps,
                                          }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper">
                                                <button type={"button"}
                                                    style={isDragging ? { color: 'red' } : undefined}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Adicionar imagens
                                                </button>
                                                &nbsp;
                                                <button type={"button"} onClick={onImageRemoveAll} hidden={this.state.images < 1}>Remover todas as images</button>
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image['data_url']} alt="" width="100" />
                                                        <div className="image-item__btn-wrapper">
                                                            {/*<button onClick={() => onImageUpdate(index)}>Update</button>*/}
                                                            <button type={"button"} onClick={() => onImageRemove(index)}>Remover</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                    <div className="rld-single-input">
                                        <label>Anexos</label>
                                        <input type="file"
                                               onChange={this.addDocuments}
                                               multiple
                                               id="property_documents" name="property_documents"
                                               accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"/>
                                        {this.state.errors.documents &&
                                            <div className="label label-danger">{this.state.errors.documents}</div>
                                        }
                                    </div>
                                    <ReCAPTCHA
                                        sitekey="6LdEujgcAAAAAHJsWaMZTT3kEHKzOcogfG0i-97w"
                                        onChange={onChange}
                                        ref={recaptchaRef}
                                    />
                                    { this.state.errors.recaptcha &&
                                        <div className="label label-danger">{this.state.errors.recaptcha}</div>
                                    }

                                    {this.state.images_upload > 0 &&
                                        <div className="text-center">
                                            <img src="/assets/img/ajax-loader.gif" alt="loading"/>
                                            Enviando imagens...
                                        </div>
                                    }
                                    {this.state.documents_upload > 0 &&
                                        <div className="text-center">
                                            <img src="/assets/img/ajax-loader.gif" alt="loading"/>
                                            Enviando anexos...
                                        </div>
                                    }
                                    <div className="btn-wrap text-center">
                                        {this.state.sending &&
                                            <button className="btn btn-yellow" disabled={true}>Enviando...</button>
                                        }
                                        {!this.state.sending &&
                                            <button className="btn btn-yellow">Enviar</button>
                                        }
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                    <div className="row pd-top-92">
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-contact-info">
                                <p><i className="fa fa-phone"/>Ligue para PortalBC:</p>
                                <h5>(47) 3311-1100</h5>
                                <h5>(47) 99109-9997</h5>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-contact-info">
                                <p><i className="fa fa-envelope"/>Tem alguma pergunta?</p>
                                <h5>comercial@portalbc.online</h5>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-contact-info">
                                <p><i className="fa fa-phone"/>Endereço</p>
                                <h5>Rua Miguel Matte, 687 - Bairro pioneiros</h5>
                                <h5>Balneário Camboriú - SC</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    }
}
function onChange(value) {
    console.log("Captcha value:", value);
}

export default Agency

