import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import LogAction from "../../helpers/LogAction";
import ConsoleHelper from "../../helpers/ConsoleHelper";

class Footer_fixed extends Component {
    state = {
        message: ''
    };
    async componentDidMount() {
        this.setState({
            message: this.props.property ? 'Olá, tenho interesse no imóvel: ' + this.props.property.title : ''
        });
    }
    render() {
        ConsoleHelper(this.state.message);
        return <div>
            <div className="footer-navbar navbar-area">
                <nav className="navbar navbar-area navbar-expand-lg footer-fixed-area">
                    <div className="container nav-container" style={{padding: '1px'}}>
                        <div className="navbar-collapse"
                             style={{background: '#333333', marginBottom: '2px', marginTop: '2px'}}>
                            <ul className="navbar-nav menu-open"
                                style={{textAlign: 'center', marginBottom: '0', marginTop: '0'}}>
                                <div className="d-block d-sm-none">
                                    <div className="row">
                                        <div className="col-6">
                                            <a href="tel:4733111100" className="btn btn-yellow btn-footer"
                                               onClick={e => LogAction('ligar', 'barra_flutuante')}>
                                                <i className="fa fa-phone"/> Ligar
                                            </a>
                                        </div>
                                        <div className="col-6">
                                            <a href={"https://api.whatsapp.com/send?phone=554733111100&text=" + this.state.message}
                                               onClick={e => LogAction('whatsApp', 'barra_flutuante')} target={'_blank'}
                                               className="btn btn-yellow btn-footer">
                                                <i className="fa fa-whatsapp"/> WhatsApp
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-none d-sm-block">
                                    <li>
                                        <Link to="/contato" className="underline-gray"
                                              onClick={e => LogAction('mailto', 'barra_flutuante')}>
                                            <i className="fa fa-envelope"/> E-mail - Entre em contato
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={"https://api.whatsapp.com/send?phone=5547991540945&text=" + this.state.message}
                                           onClick={e => LogAction('whatsApp', 'barra_flutuante')} target={'_blank'}
                                           className="underline-gray">
                                            <i className="fa fa-whatsapp"/> Enviar um WhatsApp
                                        </a>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

    }
}


export default Footer_fixed
