import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertySection from './section-components/recent-properties-for-sold';
import TopAuthor from './section-components/top-author';
import Footer from './global-components/footer';
import FooterFixed from "./global-components/footer-fixed";

const Property = () => {
  return <div>
    <Navbar/>
    <PageHeader headertitle="Imóveis"/>
    <PropertySection/>
    {/*<TopAuthor />*/}
    <Footer/>
    <FooterFixed />
  </div>
}

export default Property

