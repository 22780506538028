import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import FooterFixed from "./global-components/footer-fixed";
import HighlightPropertiesSection from './section-components/highlight-properties';

const AvailableProperty = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Imóveis em Destaque" subheader="Imóveis em Destaque" />
        <HighlightPropertiesSection />
        <Footer />
        <FooterFixed />
    </div>
}

export default AvailableProperty

