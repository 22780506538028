import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import ConsoleHelper from "../../helpers/ConsoleHelper";
import LogAction from "../../helpers/LogAction";

class Featured extends Component {

  state = {
    properties: [],
    firstitem: {}
  };

  componentDidMount() {
    axios.get(process.env.REACT_APP_API_URL + '/v1/property?order=destaque,ASC&destaque_from=1')
        .then(res => {
          const properties = res.data.data.slice(0, 9);
          const firstitem = res.data.data[0];
          ConsoleHelper(properties)
          this.setState({
            properties: properties,
            firstitem: firstitem,
          })
        })
  }

  linkUrl(property) {
    return encodeURI(process.env.REACT_APP_SHARE_URL + '/imovel/' + property.slug);
  }

  getTotalDormitorios(property) {
    let total = 0;
    total = property.suites ? parseInt(property.suites) : 0;
    total += property.demi_suites ? parseInt(property.demi_suites) : 0;
    total += property.bedrooms ? parseInt(property.bedrooms) : 0;
    return total;
  }

  getTotalBanheiros(property) {
    let total = 0;
    total = property.suites ? parseInt(property.suites) : 0;
    total += property.social_baths ? parseInt(property.social_baths) : 0;
    total += property.washrooms ? parseInt(property.washrooms) : 0;
    total += property.service_bathrooms ? parseInt(property.service_bathrooms) : 0;
    return total;
  }

  getTotalDormitoriosFirst() {
    return  this.state.firstitem.bedrooms > 0 ? this.state.firstitem.bedrooms + ' Dorms.' : '';
  }

  getTotalSuitesFirst() {
    return  this.state.firstitem.suites > 0 ? this.state.firstitem.suites + ' Suítes' : '';
  }

  render() {
    let imagealt = 'image'
    let data = sectiondata.featuredproperties
    let Customclass = this.props.Customclass ? this.props.Customclass : 'pd-top-60'


    return <div className={"featured-area  " + Customclass}>
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title">Imóveis em Destaque</h2>
        </div>
        <div className="row justify-content-center">
          {/*<div className="col-xl-6 col-lg-8">
            <a href={'https://portalbc.online/imoveis/' +this.state.firstitem.slug} target="_blank">
            <div className="single-leading-feature">
              <div className="slf-overlay"/>
              <div className="thumb" style={{maxHeight: '493px'}}>
                <img style={{width: '100%'}} src={this.state.firstitem.cover} alt={imagealt} loading="lazy"/>
              </div>
              <div className="details">
                <h4 className="title readeal-top">
                  <a href={'https://portalbc.online/imoveis/' +this.state.firstitem.slug} target="_blank">{this.state.firstitem.type} - {this.state.firstitem.city} - {this.state.firstitem.neighborhood}</a>
                </h4>
                <h5 className="price">{this.state.firstitem.price}</h5>
                <span>{this.getTotalDormitoriosFirst()} {this.getTotalSuitesFirst()} {this.state.firstitem.useful_area}m²</span>
              </div>
            </div>
            </a>
          </div>*/}
          {this.state.properties.map((item, i) =>
              <div key={i} className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                <div className="single-feature">
                  <a onClick={e => LogAction('property_click', 'home', {property_id: item.id})} href={'https://portalbc.online/imoveis/' +item.slug} target="_blank">
                    <div className="thumb" style={{height: '230px'}}>
                      <img src={item.cover} alt={imagealt} loading="lazy" style={{width:'100%', height: '100%'}}/>
                    </div>
                  </a>
                  <div className="details">
                    {/*<a href="#" className="feature-logo">*/}
                    {/*<img src={item.icon} alt={ imagealt } />*/}
                    {/*</a>*/}

                    <a onClick={e => LogAction('property_click', 'home', {property_id: item.id})} href={'https://portalbc.online/imoveis/' +item.slug} target="_blank">
                      <div className="row" style={{minHeight: '54px'}}>
                        <div className="col-10">
                          <p className="author"><i className="fa fa-building"/> {item.development.name}</p>
                        </div>
                        <div className="col-2">
                          <p className="author"> {item.id}</p>
                        </div>
                      </div>
                      <h6 className="title readeal-top" style={{minHeight: '59px'}}>
                        <a onClick={e => LogAction('property_click', 'home', {property_id: item.id})} href={'https://portalbc.online/imoveis/' +item.slug} target="_blank">{item.development.name} - {item.development.city} - COD {item.id}</a>
                      </h6>
                      <ul className="info-list" style={{borderTop:'none'}}>
                        {this.getTotalDormitorios(item) &&
                            <li><i className="fa fa-bed" title="Dormitórios"></i> {this.getTotalDormitorios(item)}</li>
                        }
                        {this.getTotalBanheiros(item) &&
                            <li><i className="fa fa-bath" title="Banheiros"></i> {this.getTotalBanheiros(item)}</li>
                        }
                        {item.parking_lots &&
                            <li><i className="fa fa-car" title="Vagas"></i> {item.parking_lots}</li>
                        }
                        {item.useful_area &&
                            <li><img src={"/assets/img/icons/7.png"} title="Área privativa" alt={imagealt} loading="lazy"/> {item.useful_area}m²
                            </li>
                        }
                      </ul>
                      <div className="info-list">
                        <h6 className="price">{item.price}</h6>{/*<del>{ item.olderprice }</del>*/}
                      </div>
                    </a>
                    <ul className="contact-list">
                      <li><a className="phone" target={"_blank"}
                             href={encodeURI('https://api.whatsapp.com/send?phone=+554733111100&text=Olá, tenho interesse no imóvel: ' + this.linkUrl(item))}><i
                          className="fa fa-whatsapp"/></a></li>
                      {/*<li><a className="message" href="#"><img src={ "/assets/img/icons/8.png" } alt="img" /></a></li>*/}
                      <li className="readeal-top" style={{minHeight: '40px'}}>
                        <a onClick={e => LogAction('property_click', 'home', {property_id: item.id})} className="btn btn-yellow" target={"_blank"} href={'https://portalbc.online/imoveis/' +item.slug}>Ver Detalhes</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          )}

        </div>
        <div className="text-center">
          <Link to="/search-list" className="btn btn-yellow">Visualizar mais Imóveis</Link>
        </div>
      </div>
    </div>


  }
}

export default Featured
