import React, {Component} from 'react';
import axios from "axios/index";
import ConsoleHelper from "../../helpers/ConsoleHelper";
import MetaTags from 'react-meta-tags';

class Terms extends Component {

  state = {
    terms: null
  };

  componentDidMount() {
    axios.get(process.env.REACT_APP_API_URL + '/lgpd/active')
        .then(res => {
          ConsoleHelper(res)
          this.setState({
            terms: res.data.term,
          })
        })
  }

  render() {

    return <div className="faq-area pd-top-100 pd-bottom-100">
      <div className="container">
        <MetaTags>
          <title>Política de Privacidade</title>
          <link rel="canonical" href={document.location.href}/>
          <meta name="description" content="Esta política de privacidade descreve os tipos de informações pessoais que poderemos coletar e como serão utilizadas por nós para prestar os serviços do PortalBC.online"/>
          <meta property="og:title" content="Política de Privacidade"/>
          <meta property="og:image" content="/assets/img/logo.png" content="image/jpeg"/>
          <meta property="og:url" content={document.location.href} />
          <meta property="og:site_name" content="Portal BC" />
        </MetaTags>
        {this.state.terms &&
        <div className="row" dangerouslySetInnerHTML={{__html: this.state.terms}}>
        </div>
        }
      </div>
    </div>
  }
}

export default Terms