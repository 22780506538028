import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import axios from "axios";
import ConsoleHelper from "../../helpers/ConsoleHelper";
import {Link} from "react-router-dom";

class RecomandProperties extends Component {

  state = {
    properties: []
  };

  async componentDidMount() {
    ConsoleHelper(this.props.property_id)
    await axios.get(process.env.REACT_APP_API_URL + '/v1/property/' + this.props.property_id + '/recommended')
        .then(async res => {
          const properties = res.data.data;
          ConsoleHelper(properties)
          await this.setState({properties: properties})
        })
        .catch(error => ConsoleHelper(error))
  }

  linkUrl(property) {
    return encodeURI(process.env.REACT_APP_SHARE_URL + '/imovel/' + property.slug);
  }

  getTotalDormitorios(property) {
    let total = 0;
    total = property.suites ? parseInt(property.suites) : 0;
    total += property.demi_suites ? parseInt(property.demi_suites) : 0;
    total += property.bedrooms ? parseInt(property.bedrooms) : 0;
    return total;
  }

  getTotalBanheiros(property) {
    let total = 0;
    total = property.suites ? parseInt(property.suites) : 0;
    total += property.social_baths ? parseInt(property.social_baths) : 0;
    total += property.washrooms ? parseInt(property.washrooms) : 0;
    total += property.service_bathrooms ? parseInt(property.service_bathrooms) : 0;
    return total;
  }

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    let data = sectiondata.recinabdproperties

    return <div className="recommended-area pd-top-60 pd-bottom-70">
      <div className="container">
        <div className="section-title">
          <h2 className="title">{data.title}</h2>
        </div>
        <div className="row">
          {this.state.properties && this.state.properties.map((item, i) =>
              <div key={i} className="col-lg-3 col-sm-6">
                <div className="single-feature">
                  <div className="thumb" style={{maxHeight: '299px'}}>
                    <img src={item.cover} alt="img"/>
                  </div>
                  <div className="details">
                    <ul className="info-list">
                      {this.getTotalDormitorios(item) &&
                      <li><i className="fa fa-bed" title="Dormitórios"></i> {this.getTotalDormitorios(item)}</li>
                      }
                      {this.getTotalBanheiros(item) &&
                      <li><i className="fa fa-bath" title="Banheiros"></i> {this.getTotalBanheiros(item)}</li>
                      }
                      {item.parking_lots &&
                      <li><i className="fa fa-car" title="Vagas"></i> {item.parking_lots}</li>
                      }
                      {item.useful_area &&
                      <li><img src={"/assets/img/icons/7.png"} title="Área privativa" alt={imagealt}/> {item.useful_area}m²</li>
                      }
                    </ul>
                    <h6 className="title"><a href={item.url}>{item.title}</a></h6>
                    <h6 className="price">{item.price}</h6>
                    <ul className="contact-list">
                      <li><a className="phone" target={"_blank"}
                             href={encodeURI('https://api.whatsapp.com/send?phone=+554733111100&text=Olá, tenho interesse no imóvel: ' + this.linkUrl(item))}><i
                          className="fa fa-whatsapp"/></a></li>
                      {/*<li><a className="message" href="#"><img*/}
                      {/*src={publicUrl + "/assets/img/icons/8.png"} alt="img"/></a></li>*/}
                      <li><a className="btn btn-yellow" target={"_blank"} replace={true} href={'https://portalbc.online/imoveis/' +item.slug}>Ver Detalhes</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          )}
        </div>
      </div>
    </div>

  }
}

export default RecomandProperties
