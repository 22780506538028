import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import NewsDetailsSection from './blog-components/news-details';
import Footer from './global-components/footer';
import FooterFixed from "./global-components/footer-fixed";

const NewsDetails = (props) => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Notícias" subheader="Notícias" />
        <NewsDetailsSection id={props.id} />
        <Footer />
        <FooterFixed />
    </div>
}

export default NewsDetails

