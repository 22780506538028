import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import ConsoleHelper from "../../helpers/ConsoleHelper";

class PropertiesByCities extends Component {

    state = {
        neighborhoods: []
    };

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/v1/neighborhoodProperties')
            .then(res => {
                const neighborhoods = res.data.slice(0, 7);
                neighborhoods.map((neighborhood, index) => {
                    if (index == 0) {
                        neighborhood.class = "col-lg-2 col-sm-6";
                        neighborhood.sectionclass = "sc-one";
                        neighborhood.maxHeight = "200px";
                        neighborhood.position = "absolute";
                        neighborhood.left = "0%";
                        neighborhood.top = "0px";
                    }
                    if (index == 1) {
                        neighborhood.class = "col-lg-2 col-sm-6";
                        neighborhood.sectionclass = "sc-two";
                        neighborhood.maxHeight = "auto";
                        neighborhood.position = "absolute";
                        neighborhood.left = "16.6176%";
                        neighborhood.top = "0px";
                    }
                    if (index == 2) {
                        neighborhood.class = "col-lg-5 col-sm-6";
                        neighborhood.sectionclass = "";
                        neighborhood.maxHeight = "410px";
                        neighborhood.position = "absolute";
                        neighborhood.left = "33.3088%";
                        neighborhood.top = "0px";
                    }
                    if (index == 3) {
                        neighborhood.class = "col-lg-3 col-sm-6";
                        neighborhood.sectionclass = "sc-three";
                        neighborhood.maxHeight = "280px";
                        neighborhood.position = "absolute";
                        neighborhood.left = "74.92%";
                        neighborhood.top = "0px";
                    }
                    if (index == 4) {
                        neighborhood.class = "col-lg-4 col-sm-6";
                        neighborhood.sectionclass = "";
                        neighborhood.maxHeight = "410px";
                        neighborhood.position = "absolute";
                        neighborhood.left = "0%";
                        neighborhood.top = "280px";
                    }
                    if (index == 5) {
                        neighborhood.class = "col-lg-4 col-sm-6";
                        neighborhood.sectionclass = "";
                        neighborhood.maxHeight = "auto";
                        neighborhood.position = "absolute";
                        neighborhood.left = "33.3333%";
                        neighborhood.top = "439px";
                    }
                    if (index == 6) {
                        neighborhood.class = "col-lg-3 col-sm-6";
                        neighborhood.sectionclass = "";
                        neighborhood.maxHeight = "auto";
                        neighborhood.position = "absolute";
                        neighborhood.left = "66.6667%";
                        neighborhood.top = "455px";
                    }
                })
                ConsoleHelper(neighborhoods)
                this.setState({neighborhoods: neighborhoods})
            })
    }

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'

        const inlineStyle = {
            backgroundImage: 'url(' + publicUrl + '/assets/img/bg/2.png)',
            minHeight: '900px'
        }

        return <div className="city-intro-area pd-bottom-70" style={inlineStyle}>
            {/* city area start */}
            <div className="city-area pd-top-92">
                <div className="container">
                    <div className="section-title text-center">
                        <h2 className="title">Imóveis por Bairro</h2>
                    </div>
                    <div className="city-filter-area row">
                        {this.state.neighborhoods.map((item, i) =>
                            <Link target={"_blank"} to={'/search-list?neighborhood=' + item.neighborhood.split('/')[0] + '&city=' + item.neighborhood.split('/')[1]}>
                                <div key={i} className={"rld-city-item " + item.class} style={{maxHeight: item.maxHeight, position: item.position, top: item.top, left: item.left}}>
                                    <div className={"single-city " + item.sectionclass}>
                                        <div className="sc-overlay"/>
                                        <div className="thumb">
                                            <img src={item.cover} alt={imagealt} style={{maxHeight: item.maxHeight, width: '100%'}} loading="lazy"/>
                                        </div>
                                        <div className="details">
                                            <h5 className="title">
                                                <Link target={"_blank"} to={'/search-list?neighborhood=' + item.neighborhood.split('/')[0] + '&city=' + item.neighborhood.split('/')[1]}>{item.neighborhood}</Link>
                                            </h5>
                                            <p className="text-center">{item.total} Imóveis</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )}

                    </div>
                </div>
            </div>
        </div>


    }
}

export default PropertiesByCities
