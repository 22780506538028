import React, {Component} from 'react';
import parse from 'html-react-parser';
import {Link, withRouter} from 'react-router-dom';
import axios from "axios/index";
import queryString from 'query-string';
import ConsoleHelper from "../../helpers/ConsoleHelper";
import MetaTags from 'react-meta-tags';
import CurrencyInput from 'react-currency-input';
import LogAction from "../../helpers/LogAction";

class SearchList extends Component {

  state = {
    properties: [],
    neighborhoods: [],
    cities: [],
    types: [],
    filters: {},
    loading: true,
    meta: {
      current_page: 0,
      last_page: 1,
      total: 0,
      per_page: 10
    }
  };

  async componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    ConsoleHelper(params)
    if (params) {
      await this.setState({
        filters: params
      });
    }
    this.loadNeighborhoods();
    this.loadCities();
    this.loadTypes();
    await this.search();
  }

  loadNeighborhoods() {
    axios.get(process.env.REACT_APP_API_URL + '/v1/neighborhoodProperties')
        .then(res => {
          const neighborhoods = res.data;
          ConsoleHelper(neighborhoods)
          this.setState({neighborhoods: neighborhoods})
        })
        .catch(error => {
          ConsoleHelper(error)
        })
  }

  loadCities() {
    axios.get(process.env.REACT_APP_API_URL + '/v1/cities')
        .then(res => {
          const cities = res.data;
          ConsoleHelper(cities)
          this.setState({cities: cities})
        })
        .catch(error => {
          ConsoleHelper(error)
        })
  }

  loadTypes() {
    axios.get(process.env.REACT_APP_API_URL + '/v1/type')
        .then(res => {
          const types = res.data;
          ConsoleHelper(types)
          this.setState({types: types})
        })
        .catch(error => {
          ConsoleHelper(error)
        })
  }

  encodeGetParams = p =>
      Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

  search = async (e, resetPager = false) => {
    if (e) {
      e.preventDefault();
    }
    window.scrollTo(0, 0);
    await this.setState({
      loading: true
    })
    if (!this.state.filters.base_screen) {
      this.state.filters.base_screen = 'search_list';
    }
    if (resetPager) {
      this.state.filters.page  = 1;
    }
    axios.get(process.env.REACT_APP_API_URL + '/v1/property/search?' + this.encodeGetParams(this.state.filters))
        .then(async res => {
          const properties = res.data.data;
          ConsoleHelper(properties)

          this.state.filters.base_screen = 'search_list';
          await this.setState({
            properties: properties,
            loading: false,
            meta: res.data.meta,
            filters: this.state.filters
          })
        })
        .catch(async error => {
          await this.setState({
            loading: false
          })
        });
  }

  handleInputChange = async e => {
    await this.setState({
      filters: {...this.state.filters, [e.target.name]: e.target.value}
    });
    await this.search(null, true);
  }

  changeOrder = async e => {
    await this.setState({
      filters: {...this.state.filters, [e.target.name]: e.target.value}
    });
    await this.search(null, true);
  }

  async changePage(page = 1) {
    await this.setState({
      filters: {
        ...this.state.filters,
        page: page
      }
    });
    await this.search();
  }

  getTotalDormitorios(property) {
    let total = 0;
    total = property.suites ? parseInt(property.suites) : 0;
    total += property.demi_suites ? parseInt(property.demi_suites) : 0;
    total += property.bedrooms ? parseInt(property.bedrooms) : 0;
    return total;
  }

  getTotalBanheiros(property) {
    let total = 0;
    total = property.suites ? parseInt(property.suites) : 0;
    total += property.social_baths ? parseInt(property.social_baths) : 0;
    total += property.washrooms ? parseInt(property.washrooms) : 0;
    total += property.service_bathrooms ? parseInt(property.service_bathrooms) : 0;
    return total;
  }

  toggleFilter() {
    console.log(document.getElementById('formSearchList').classList)
    if (document.getElementById('formSearchList').classList.contains('search-filter')) {
      document.getElementById('formSearchList').className = document.getElementById('formSearchList').className.replace('search-filter', '');
    } else {
      document.getElementById('formSearchList').className = document.getElementById('formSearchList').className + ' search-filter';
    }
  }

  clearFilters = async e => {
     await this.setState({
      filters: {
        ...this.state.filters = {},
      }
    });
    document.getElementById("formSearchList").reset();
  }

  getPriceFromOther() {
    const price_from = this.state.filters.price_from;
    let other = false;
    switch (price_from) {
      case '300000':
      case '700000':
      case '1000000':
      case '2000000':
      case '3000000':
      case '4000000':
      case '5000000':
      case null:
      case undefined:
        other = false;
        break;
      default:
        other = true;
        break;
    }
    return other;
  }

  restorePriceFromSelect = e => {
    console.log(e.target.value)
    if (e.target.value != '0') {
      this.setState({
        filters: {...this.state.filters, price_from: e.target.value}
      });
    }
  }

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'


    return <div className="search-page-wrap pd-top-50 pd-bottom-70">
      <MetaTags>
        <title>Buscar Imóveis</title>
        <link rel="canonical" href={document.location.href}/>
        <meta name="description" content="Encontre casas, apartamento, apartamento diferenciado, apartamento decorado, coberturas e imóveis para compra em Balneário Camboriú, Itapema, Meia Praia, Porto Belo, Porto Alegre"/>
        <meta property="og:title" content="Comprar Imóveis"/>
        <meta property="og:image" content="/assets/img/logo.png" content="image/jpeg"/>
        <meta property="og:url" content={document.location.href} />
        <meta property="og:site_name" content="Portal BC" />
      </MetaTags>
      <div className="search-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 sitebar">

              <h6 className="filter-title mb-4" onClick={e => this.toggleFilter()}>
                <img className="mr-3" src={publicUrl + "assets/img/icons/18.png"} alt={imagealt} loading="lazy"/>Filtros disponíveis
              </h6>

              <form id="formSearchList" className="search-filter widget widget-sidebar-search-wrap" onSubmit={this.search}>
                <div className="widget-sidebar-search">
                  <div className="widget-sidebar-item-wrap rld-single-input">
                    <div className="row">
                    <div className="col-10">
                      <input className="form-control" style={{fontSize: '13px', padding: '0 10px'}} type="text"
                             placeholder="Código/Título/Empreend.." onChange={this.handleInputChange}
                             value={this.state.filters.name} id="name" name="name"/>
                    </div>
                    <div className="col-2">
                      <button onClick={e => this.toggleFilter()} style={{marginLeft: '-35px', height: '48px'}} className="btn btn-yellow">
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                    </div>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-input left-icon">
                    <input type="text" placeholder="Endereço" onChange={this.handleInputChange}
                           value={this.state.filters.address} id="address" name="address"/>
                  </div>

                  {(this.state.cities.length > 0 &&
                      <div className="widget-sidebar-item-wrap rld-single-select">
                        <div className="title d-inline-block float-left mb-0 pt-2">Cidades</div>
                        <select className="select single-select" name="city" value={this.state.filters.city}
                                onChange={this.handleInputChange}>
                          <option key={'all'} value={''}>Todas</option>
                          {this.state.cities.map((city, i) =>
                              <option key={i} value={city.city}>
                                {city.city}
                              </option>
                          )}
                        </select>
                      </div>
                  )}
                  {(this.state.neighborhoods.length > 0 &&
                      <div className="widget-sidebar-item-wrap rld-single-select">
                        <div className="title d-inline-block float-left mb-0 pt-2">Bairros</div>
                        <select className="select single-select" name="neighborhood"
                                value={this.state.filters.neighborhood} onChange={this.handleInputChange}>
                          <option key={'all'} value={''}>Todos</option>
                          {this.state.neighborhoods.map((neighborhood, i) =>
                              <option hidden={this.state.filters.city != neighborhood.neighborhood.split('/')[1]}
                                      value={neighborhood.neighborhood.split('/')[0]}>
                                {neighborhood.neighborhood} ({neighborhood.total})
                              </option>
                          )}
                        </select>
                      </div>
                  )}
                  {(this.state.types.length > 0 &&
                      <div className="widget-sidebar-item-wrap rld-single-select">
                        <div className="title d-inline-block float-left mb-0 pt-2">Tipo</div>
                        <select className="select single-select" name="type" value={this.state.filters.type}
                                onChange={this.handleInputChange}>
                          <option key={'all'} value={''}>Todos</option>
                          {this.state.types.map((type, i) =>
                              <option key={i}
                                      value={type}>{type}</option>
                          )}
                        </select>
                      </div>
                  )}
                  <div className="widget-sidebar-item-wrap rld-single-select">
                    <div className="title d-inline-block float-left mb-0 pt-2">Valor acima de:</div>
                    {!this.getPriceFromOther() &&
                        <select className="select single-select" onChange={this.handleInputChange}
                                value={this.state.filters.price_from} id="price_from" name="price_from">
                          <option value={'300000'}>300.000+</option>
                          <option value={'700000'}>700.000+</option>
                          <option value={'1000000'}>1.000.000+</option>
                          <option value={'2000000'}>2.000.000+</option>
                          <option value={'3000000'}>3.000.000+</option>
                          <option value={'4000000'}>4.000.000+</option>
                          <option value={'5000000'}>5.000.000+</option>
                          <option value={'0'}>Informar um valor...</option>
                        </select>
                    }
                    {this.getPriceFromOther() &&
                        <select className="select single-select" onChange={this.restorePriceFromSelect}
                                id="price_from" name="price_from">
                          <option value={'0'}>Informar um valor...</option>
                          <option value={'300000'}>300.000+</option>
                          <option value={'700000'}>700.000+</option>
                          <option value={'1000000'}>1.000.000+</option>
                          <option value={'2000000'}>2.000.000+</option>
                          <option value={'3000000'}>3.000.000+</option>
                          <option value={'4000000'}>4.000.000+</option>
                          <option value={'5000000'}>5.000.000+</option>
                        </select>
                    }
                  </div>
                  { this.getPriceFromOther() &&
                    <div className="widget-sidebar-item-wrap rld-single-input">
                      <div className="title d-inline-block float-left mb-0 pt-2">Valor acima de:</div>
                      <CurrencyInput autoFocus type="tel" onChangeEvent={this.handleInputChange}
                             value={this.state.filters.price_from} id="price_from" name="price_from"
                             decimalSeparator="," thousandSeparator="."
                             min="0"/>
                    </div>
                  }
                  <div className="widget-sidebar-item-wrap rld-single-select">
                    <div className="title d-inline-block float-left mb-0 pt-2">Quadramar</div>
                    <select className="select single-select" onChange={this.handleInputChange}
                            value={this.state.filters.square} id="square" name="square">
                      <option value={''}>Todos</option>
                      <option value={1}>Sim</option>
                      <option value={0}>Não</option>
                    </select>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select">
                    <div className="title d-inline-block float-left mb-0 pt-2">Frente ao mar</div>
                    <select className="select single-select" onChange={this.handleInputChange}
                            value={this.state.filters.front_sea} id="front_sea" name="front_sea">
                      <option value={''}>Todos</option>
                      <option value={1}>Sim</option>
                      <option value={0}>Não</option>
                    </select>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                    <div className="title d-inline-block float-left mb-0 pt-2">Suítes</div>
                    <div className="rld-single-select d-inline-block float-right">
                      <select className="select single-select" onChange={this.handleInputChange}
                              value={this.state.filters.suites_from} id="suites_from" name="suites_from">
                        <option value={0}>0+</option>
                        <option value={1}>1+</option>
                        <option value={2}>2+</option>
                        <option value={3}>3+</option>
                        <option value={4}>4+</option>
                      </select>
                    </div>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                    <div className="title d-inline-block float-left mb-0 pt-2">Demi Suítes</div>
                    <div className="rld-single-select d-inline-block float-right">
                      <select className="select single-select" onChange={this.handleInputChange}
                              value={this.state.filters.demi_suites_from} id="demi_suites_from" name="demi_suites_from">
                        <option value={0}>0+</option>
                        <option value={1}>1+</option>
                        <option value={2}>2+</option>
                        <option value={3}>3+</option>
                        <option value={4}>4+</option>
                      </select>
                    </div>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                    <div className="title d-inline-block float-left mb-0 pt-2">Dormitórios</div>
                    <div className="rld-single-select d-inline-block float-right">
                      <select className="select single-select" onChange={this.handleInputChange}
                              value={this.state.filters.bedrooms_from} id="bedrooms_from" name="bedrooms_from">
                        <option value={0}>0+</option>
                        <option value={1}>1+</option>
                        <option value={2}>2+</option>
                        <option value={3}>3+</option>
                        <option value={4}>4+</option>
                      </select>
                    </div>
                  </div>
                  <div className="widget-sidebar-item-wrap rld-single-select-wrap mb-0">
                    <div className="title d-inline-block float-left mb-0 pt-2">Vagas</div>
                    <div className="rld-single-select d-inline-block float-right">
                      <select className="select single-select" onChange={this.handleInputChange}
                              value={this.state.filters.parking_lots_from} id="parking_lots_from"
                              name="parking_lots_from">
                        <option value={0}>0+</option>
                        <option value={1}>1+</option>
                        <option value={2}>2+</option>
                        <option value={3}>3+</option>
                        <option value={4}>4+</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="btn-wrap text-center">
                  <button className="btn btn-yellow"><span className="left"><i className="fa fa-search"/></span>Buscar</button>
                  <button className="btn btn-outline-warning" style={{marginTop: '10px'}}  onClick={this.clearFilters}>
                    <span className="left"><i className="fa fa-eraser"/></span>Limpar
                  </button>
                </div>
              </form>

            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="row mb-3">
                <div className="col-md-8 col-sm-8">
                  { !this.state.loading &&
                  <h6 className="filter-title mt-3 mb-lg-0">
                    {this.state.meta.total == 1 ? this.state.meta.total + ' imóvel' : this.state.meta.total + ' imóveis'}
                  </h6>
                  }
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="rld-single-select">
                    <select id="order" name="order" className="select single-select" onChange={this.changeOrder}
                            value={this.state.filters.order}>
                      <option value="">Ordenar por</option>
                      <option value={'price,DESC'}>Maior preço</option>
                      <option value={'price,ASC'}>Menor preço</option>
                      <option value={'updated_at,DESC'}>Mais recente primeiro</option>
                      <option value={'updated_at,ASC'}>Mais antigo primeiro</option>
                    </select>
                  </div>
                </div>
              </div>

              {this.state.loading &&
              <div>
                Carregando imóveis...
              </div>
              }

              {!this.state.loading && this.state.properties.length < 1 &&
              <div>
                Nenhum imóvel para exibir com estes filtros.
              </div>
              }

              {!this.state.loading && this.state.properties.map((item, i) =>
                  <a onClick={e => LogAction('property_click', 'search-list', {property_id: item.id})} href={'https://portalbc.online/imoveis/' +item.slug} target="_blank">
                    <div key={i} className="single-feature style-two">
                      <div className="thumb" style={{maxHeight: '244px'}}>
                        <img src={item.cover} alt="img" loading="lazy"/>
                      </div>
                      <div className="details">
                        <div className="details-wrap">
                          <div className="row">
                            <div className="col-10">
                              <p className="author" style={{marginBottom: '3px'}}><i className="fa fa-building"/> {item.development.name}</p>
                            </div>
                            <div className="col-2">
                              <p className="author" style={{marginBottom: '3px'}}> {item.id}</p>
                            </div>
                          </div>
                          <h6 className="readeal-top">
                            <a onClick={e => LogAction('property_click', 'home', {property_id: item.id})} href={'https://portalbc.online/imoveis/' +item.slug} target="_blank">{item.development.name} - {item.development.city} - COD {item.id}</a>
                          </h6>
                          <ul className="info-list" style={{borderTop:'none', margin:0, padding: '13px 0 0 0'}}>
                            {this.getTotalDormitorios(item) &&
                                <li><i className="fa fa-bed" title="Dormitórios"></i> {this.getTotalDormitorios(item)}
                                </li>
                            }
                            {this.getTotalBanheiros(item) &&
                                <li><i className="fa fa-bath" title="Banheiros"></i> {this.getTotalBanheiros(item)}</li>
                            }
                            <li><i className="fa fa-car" title="Vagas"></i> {item.parking_lots}</li>
                            <li><img src={"/assets/img/icons/7.png"} title="Área privativa"
                                     alt={imagealt} loading="lazy"/> {item.useful_area}  </li>
                          </ul>
                          <div className="info-list" style={{padding: '5px 0 0 0'}}>
                            <h6 className="price">{item.price}</h6>
                          </div>
                          <ul className="contact-list">
                            {/*<li><a className="phone" href="#"><i className="fa fa-phone"/></a></li>*/}
                            {/*<li><a className="message" href="#"><img src={"/assets/img/icons/8.png"} alt="img"/></a></li>*/}
                            <li className="readeal-top">
                              <a onClick={e => LogAction('property_click', 'home', {property_id: item.id})} className="btn btn-yellow" target={"_blank"} href={'https://portalbc.online/imoveis/' +item.slug}>Ver
                                Detalhes</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </a>
              )}


              {!this.state.loading && this.state.meta.total > 10 &&
              <div className="pagination">
                <ul>
                  {this.state.meta.current_page > 3 &&
                  <li key={'first'}>
                    <a className="btn btn-outline-warning" title="Primeira Página"
                       onClick={(e) => this.changePage(1)}>
                      <i className="fa fa-angle-double-left"></i>
                    </a>
                  </li>
                  }
                  {this.state.meta.current_page > 1 &&
                  <li key={'back'}><a className="btn btn-outline-warning" title="Anterior"
                                      onClick={(e) => this.changePage(this.state.meta.current_page - 1)}><i
                      className="fa fa-chevron-left"></i></a></li>
                  }
                  {Array.apply(null, {length: this.state.meta.last_page}).map((i, index) =>
                      <li key={index + 1}>
                        <a className={this.state.meta.current_page == index + 1 ? 'btn btn-yellow' : 'btn btn-outline-warning'}
                           style={{display: this.state.meta.total > 30 && (this.state.meta.current_page + 2 < index + 1 || this.state.meta.current_page - 2 > index + 1) ? 'none' : ''}}
                           title={'Página ' + (index + 1)}
                          onClick={(e) => this.changePage(index + 1)}>{index + 1}</a>
                      </li>
                  )}
                  {this.state.meta.current_page < this.state.meta.last_page &&
                  <li key={'next'}><a className="btn btn-outline-warning" title="Seguinte"
                                      onClick={(e) => this.changePage(this.state.meta.current_page + 1)}><i
                      className="fa fa-chevron-right"></i></a></li>
                  }
                  {this.state.meta.current_page < this.state.meta.last_page &&
                  <li key={'first'}>
                    <a className="btn btn-outline-warning" title="Última Página"
                       onClick={(e) => this.changePage(this.state.meta.last_page)}>
                      <i className="fa fa-angle-double-right"></i>
                    </a>
                  </li>
                  }
                </ul>
              </div>
              }


            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default withRouter(SearchList)
