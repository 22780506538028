import axios from "axios";
import ConsoleHelper from "./ConsoleHelper";

const LogAction = (event, baseScreen, information = null) => {

    const params = {
        event: event,
        base_screen: baseScreen,
        information: information ?? '',
    };
    axios.post(process.env.REACT_APP_API_URL + '/v1/log/action', params)
        .then(res => {
            console.log(res)
        })
        .catch(error => {
            console.log(error)
        });
}
const encodeGetParams = p =>
    Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

export default LogAction;
