import React, {Component} from 'react';
import ImageGallery from 'react-image-gallery';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-image-gallery/styles/css/image-gallery.css";
import {FacebookShareButton, WhatsappShareButton} from "react-share";
import axios from "axios";
import ConsoleHelper from "../../helpers/ConsoleHelper";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Circle } from "react-google-maps"

import InputMask from 'react-input-mask';
import LogAction from "../../helpers/LogAction";
import _react2 from "react-image-gallery";
import _SVG2 from "react-image-gallery";

class PropertyDetails extends Component {

  state = {
    messageSent: false,
    form: {
      message: 'Olá, gostaria de saber mais sobre o imóvel ' + this.props.property.title
    },
    errors: {},
    sending: false,
    showThumbnails: true,
    latitude: parseFloat(this.props.property.latitude) + -0.0020,
    longitude: parseFloat(this.props.property.longitude) + -0.0025,
  }
  title = {}

  postUrl() {
    return this.props.property.share_link;
  }

  buttonShareStyles(type) {
    let color = 'auto';
    switch (type) {
      case 'whatsapp':
        color = '#075E54';
        break;
      case 'facebook':
        color = '#3b5998';
        break;
    }
    return {
      backgroundColor: 'white',
      color: color,
      height: '60px',
      width: '60px',
      borderRadius: '4px',
      lineHeight: '58px',
      fontSize: '20px',
      border: '0',
      boxShadow: '0px 20px 40px rgb(0 0 0 / 12%)'
    }
  }


  sendContact = async e => {
    e.preventDefault();
    this.setState({sending: true, errors: {}});

    let data = this.state.form;
    if (this.props.property.realtor) {
      data.sendEmail = true;
      data.sendEmailTo = this.props.property.realtor.id;
      data.propertyAbout = this.props.property.id;
    }

    axios.post(process.env.REACT_APP_API_URL + '/v1/contact', data)
        .then(res => {
          this.setState({sending: false});
          const success = res.data;
          ConsoleHelper(success)
          this.setState({messageSent: true})
        })
        .catch((error) => {
          this.setState({sending: false});
          ConsoleHelper(error, error.response)
          const data = error.response.data;
          if (typeof data.errors != 'undefined') {
            this.setState({errors: data.errors})
          }
        });
  }

  handleInputChange = e => {
    this.setState({
      form: {...this.state.form, [e.target.name]: e.target.value}
    });
  }

  getTotalDormitorios() {
    let total = 0;
    total = this.props.property.suites ? parseInt(this.props.property.suites) : 0;
    total += this.props.property.demi_suites ? parseInt(this.props.property.demi_suites) : 0;
    total += this.props.property.bedrooms ? parseInt(this.props.property.bedrooms) : 0;
    return total;
  }

  getTotalBanheiros() {
    let total = 0;
    total = this.props.property.suites ? parseInt(this.props.property.suites) : 0;
    total += this.props.property.social_baths ? parseInt(this.props.property.social_baths) : 0;
    total += this.props.property.washrooms ? parseInt(this.props.property.washrooms) : 0;
    total += this.props.property.service_bathrooms ? parseInt(this.props.property.service_bathrooms) : 0;
    return total;
  }

  getTitle() {
    let title = this.props.property.title.split('- ');
    return title[0];
  }

  getPhone() {
    let phone = this.props.property.realtor.phone.replace(/\D/g,'');
    return phone;
  }

  getMicrodata() {
    const data = {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": this.props.property.title,
      "image": this.props.property.cover,
      "description": this.props.property.description,
      "url": process.env.PUBLIC_URL + '/imovel/' + this.props.property.slug,
      "brand": {
        "@type": "Brand",
        "name": "PortalBC online",
        "logo": "/assets/img/logo.png"
      },
      "offers": {
        "@type": "Offer",
        "price": this.props.property.price
      }
    }
    return JSON.stringify(data);
  }

  getRealtorPhone(unmasked = false) {
    let phone = '';
    if (this.props.property.contato_portalbc == 1 && this.props.property.portalbc_celular) {
      phone = this.props.property.portalbc_celular;
    } else {
      phone = this.props.property.realtor.phone;
    }
    return unmasked ? phone : phone.replace(/\D/g,'');
  }

  getRealtorEmail() {
      let email = '';
      if (this.props.property.contato_portalbc == 1 && this.props.property.portalbc_email) {
        email = this.props.property.portalbc_email;
      } else {
        email = this.props.property.realtor.email;
      }
      return email;
  }

  render() {

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap defaultZoom={15}
                   defaultCenter={{ lat: this.state.latitude, lng: this.state.longitude }}>
          <Circle center={{ lat: this.state.latitude, lng: this.state.longitude }}
                  radius={300}
                  options={{fillColor:"#FDA94F", strokeColor: "#FDA94F"}}>
          </Circle>
        </GoogleMap>
    ))
    
    const microData = <script> {this.getMicrodata()} </script>

    let imagealt = 'image'

    return <div className="property-details-area">
      {microData}
      <div className="container" style={{float: 'right', marginRight: '7%'}}>
        <div className="news-details-author-social">
          <div className="row">
            <div className="col-sm-6 mb-4 mg-sm-0">
            </div>
            <div className="col-sm-6 text-right">
              <ul className="social-icon style-two text-sm-right">
                <li>
                  <div style={{
                    color: '#212529b3',
                    height: '60px',
                    verticalAlign: 'top',
                    fontWeight: 'bold',
                    paddingTop: '27px'
                  }}>Fale agora:
                  </div>
                </li>
                <li key={1}>
                  <a href={"https://api.whatsapp.com/send?phone=554733111100"} target={'_blank'}>
                    <i className="fa fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray pd-top-60 pd-bottom-60">
        <div className="container">
          <div className="row ">
            <div className="col-xl-9 col-lg-9">
              {this.props.property.internalPhotos &&
              <ImageGallery lazyLoad={true} showThumbnails={true}
                            onScreenChange={(fullScreen) => {
                              console.log(fullScreen)
                              this.setState({
                                showThumbnails: !fullScreen
                              })
                            }}
                            showThumbnails={this.state.showThumbnails}
                            items={this.props.property.internalPhotos.map((propertyPhoto) => {
                              return {
                                original: propertyPhoto.path,
                                thumbnail: propertyPhoto.path
                              }
                            })}
                            renderFullscreenButton={(onClick, isFullscreen) => (
                                <button type="button" class="image-gallery-icon image-gallery-fullscreen-button"
                                        aria-label="Open Fullscreen" onClick={onClick}
                                        style={{ backgroundColor: '#908d8d57', padding: '10px'}}>
                                  <svg class="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                       fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                       stroke-linejoin="round">
                                    <path
                                        d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                                  </svg> Ver tela cheia</button>
                            )}
                            renderPlayPauseButton={(onClick, isPlaying) => (
                                <button type="button" class="image-gallery-icon image-gallery-play-button"
                                        aria-label="Play or Pause Slideshow" onClick={onClick}
                                        style={{ backgroundColor: '#908d8d57', padding: '10px'}}>
                                  <svg className="image-gallery-svg" xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                       stroke-linecap="round" stroke-linejoin="round">
                                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                  </svg> <span>Visualizar todas</span> </button>
                            )}
              />
              }
              <div className="property-details-slider-info">
                <h3><span>{this.props.property.price}</span></h3>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 d-none d-sm-block">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                {this.props.property.realtor &&
                <div className="owner-info text-center">
                  <div className="thumb" style={{width: '50%'}}>
                    <img src={this.props.property.realtor.avatar} loading="lazy" alt={imagealt}/>
                  </div>

                  <div className="details">
                    <h6>{this.props.property.realtor.name}</h6>
                    <span className="designation">Corretor</span>
                    {this.props.property.realtor.realtor_doc_number &&
                    <div>
                      <span className="designation"> {this.props.property.realtor.realtor_doc_number}</span>
                    </div>
                    }
                    {this.props.property.realtor.phone &&
                    <div style={{color: '#FDA94F'}}>
                      <a href={"https://api.whatsapp.com/send?phone=55" + this.getRealtorPhone()} target={'_blank'}
                         onClick={e => LogAction('whatsApp', 'property_detail', {corretor: this.props.property.realtor.name, telefone: this.getRealtorPhone() })}>
                        <i className="fa fa-whatsapp"></i><span className="designation">Celular: {this.getRealtorPhone(true)}</span>
                      </a>
                    </div>
                    }
                    {this.getRealtorEmail() &&
                      <p className="reviews" style={{textAlign: 'center'}}>
                        <a href={"mailto:" + this.getRealtorEmail()}
                           onClick={e => LogAction('mailto', 'property_detail', {corretor: this.props.property.realtor.name, email: this.getRealtorEmail() })}>
                          {this.getRealtorEmail()}
                        </a>
                      </p>
                    }
                  </div>
                </div>
                }
                <div className="contact">
                  <h6>Entre em Contato</h6>
                  {this.state.messageSent &&
                  <p><i className="fa fa-check"></i> Mensagem enviada com sucesso!</p>
                  }
                  {!this.state.messageSent &&
                  <form onSubmit={this.sendContact}>
                    <div className="rld-single-input">
                      <input onChange={this.handleInputChange} value={this.state.form.name} id="name" name="name"
                             placeholder="Nome" maxLength={255}/>
                    </div>
                    <div className="rld-single-input">
                      <input onChange={this.handleInputChange} value={this.state.form.email} id="email" name="email"
                             type={"email"} placeholder="E-mail" maxLength={255}/>
                    </div>
                    <div className="rld-single-input">
                      <InputMask {...this.props} onChange={this.handleInputChange} value={this.state.form.phone}
                                 name="phone" id="phone" type="tel" placeholder="(00)00000-0000" mask="(99)99999-9999"
                                 maskChar=" "/>
                    </div>
                    {this.props.property.title &&
                    <div className="rld-single-input">
                      <textarea value={this.state.form.message} style={{
                        padding: 0,
                        margin: 0,
                        background: '#F6F7FB',
                        border: 0,
                        borderBottom: '1px solid rgba(197, 197, 197, 0.5)',
                        overflow: 'hidden'
                      }} name="message" id="message"></textarea>
                    </div>
                    }
                    <div className="btn-wrap text-center">
                      {this.state.sending &&
                      <button className="btn btn-yellow" disabled={true}>Enviando...</button>
                      }
                      {!this.state.sending &&
                      <button className="btn btn-yellow">Enviar Mensagem</button>
                      }
                    </div>
                  </form>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pd-top-60">
          <div className="col-xl-9 col-lg-9">
            <div className="property-info mb-5">
              <div className="row">
                {this.getTotalDormitorios() > 0 &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Dormitórios</h5>
                    <p><i className="fa fa-bed"/>{this.getTotalDormitorios()}</p>
                  </div>
                </div>
                }
                {this.getTotalBanheiros() > 0 &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Banheiros</h5>
                    <p><i className="fa fa-bath"/>{this.getTotalBanheiros()}</p>
                  </div>
                </div>
                }
                {this.props.property.parking_lots &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Vagas</h5>
                    <p><i className="fa fa-car"/>{this.props.property.parking_lots}</p>
                  </div>
                </div>
                }
                {this.props.property.useful_area &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Área privativa</h5>
                    <p><img src={"/assets/img/icons/7.png"} alt={imagealt} loading="lazy"/>{this.props.property.useful_area}m²</p>
                  </div>
                </div>
                }
              </div>
            </div>
            {this.props.property.videos && this.props.property.videos.length > 0 &&
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Vídeos</h4>
              <div className="row">
                {this.props.property.videos.map((video, i) =>
                    <div className="col-sm-6">
                      {video.type == 'upload' &&
                      <video controls controlsList="nodownload" src={video.path}
                             style={{width: '100%', maxHeight: '300px', backgroundColor: 'black'}}></video>
                      }
                      {video.type == 'youtube' &&
                      <iframe src={video.path} frameborder="0"></iframe>
                      }
                    </div>
                )}
              </div>
            </div>
            }
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Descrição</h4>
              <div dangerouslySetInnerHTML={{__html: this.props.property.description}}></div>
              {/*<p>{this.props.property.description}</p>*/}
            </div>
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Gostou do imóvel? Compartilhe</h4>
              <div>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="social-icon style-two">
                      <li key={1}>
                        <WhatsappShareButton url={this.postUrl()} style={this.buttonShareStyles('whatsapp')}
                                             title={'Veja esse imóvel: ' + this.getTitle() +' em '}
                                             className={'facebook'}
                                             windowWidth={window.outerWidth} windowHeight={window.outerHeight}
                                             onClick={e => LogAction('share_whatsapp', 'property_detail', {id: this.props.property.id})}>
                          <i className="fa fa-whatsapp"></i>
                        </WhatsappShareButton>
                      </li>
                      <li key={2}>
                        <FacebookShareButton url={this.postUrl()} style={this.buttonShareStyles('facebook')}
                                             title={'Veja esse imóvel: ' + this.props.property.title +  ' em ' + this.postUrl()}
                                             className={'facebook'}
                                             onClick={e => LogAction('share_facebook', 'property_detail', {id: this.props.property.id})}>
                          <i className="fa fa-facebook"></i>
                        </FacebookShareButton>
                      </li>
                    </ul>
                  </div>
                  </div>
              </div>
            </div>
            {this.props.property.development && this.props.property.development.condominium &&
            <div>
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Descrição do Empreendimento</h4>
                <div dangerouslySetInnerHTML={{__html: this.props.property.development.condominium}}></div>
              </div>
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Fotos do Empreendimento</h4>
                {this.props.property.development.photos &&
                <ImageGallery lazyLoad={true} showThumbnails={true}
                              items={this.props.property.development.photos.map((propertyPhoto) => {
                                return {
                                  original: propertyPhoto.path,
                                  thumbnail: propertyPhoto.path
                                }
                              })}/>
                }
              </div>
            </div>
            }
            {this.props.property.development && this.props.property.development.plants &&
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Plantas</h4>
              <OwlCarousel className='property-details-slider owl-black' dots={false} items={1} loop margin={10} nav>
                {this.props.property.development.plants.map((item, i) =>
                    <div key={i} className="item" style={{maxWidth: "1000px", maxHeight: "600px"}}>
                      <div className="thumb">
                        <img src={item.path} style={{width: "100%", maxHeight: "600px"}} loading="lazy"/>
                      </div>
                    </div>
                )}
              </OwlCarousel>
            </div>
            }
            <div className="col-xl-3 col-lg-3 d-block d-sm-none">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                {this.props.property.realtor &&
                    <div className="owner-info text-center">
                      <div className="thumb" style={{width: '50%'}}>
                        <img src={this.props.property.realtor.avatar} alt={imagealt} loading="lazy"/>
                      </div>

                      <div className="details">
                        <h6>{this.props.property.realtor.name}</h6>
                        <span className="designation">Corretor</span>
                        {this.props.property.realtor.realtor_doc_number &&
                            <div>
                              <span className="designation">{this.props.property.realtor.realtor_doc_number}</span>
                            </div>
                        }
                        {this.props.property.realtor.phone &&
                            <div style={{color: '#FDA94F'}}>
                              <a href={"https://api.whatsapp.com/send?phone=55" + this.getPhone()} target={'_blank'}
                                 onClick={e => LogAction('whatsApp', 'property_detail', {corretor: this.props.property.realtor.name, telefone: this.getRealtorPhone() })}>
                                <i className="fa fa-whatsapp"></i><span className="designation">Celular: {this.getRealtorPhone(true)}</span>
                              </a>
                            </div>
                        }
                        {this.getRealtorEmail() &&
                            <p className="reviews" style={{textAlign: 'center'}}>
                              <a href={"mailto:" + this.getRealtorEmail()}>{this.getRealtorEmail()}</a>
                            </p>
                        }
                      </div>
                    </div>
                }
                <div className="contact">
                  <h6>Entre em Contato</h6>
                  {this.state.messageSent &&
                      <p><i className="fa fa-check"></i> Mensagem enviada com sucesso!</p>
                  }
                  {!this.state.messageSent &&
                      <form onSubmit={this.sendContact}>
                        <div className="rld-single-input">
                          <input onChange={this.handleInputChange} value={this.state.form.name} id="name" name="name"
                                 placeholder="Nome" maxLength={255}/>
                        </div>
                        <div className="rld-single-input">
                          <input onChange={this.handleInputChange} value={this.state.form.email} id="email" name="email"
                                 type={"email"} placeholder="E-mail" maxLength={255}/>
                        </div>
                        <div className="rld-single-input">
                          <InputMask {...this.props} onChange={this.handleInputChange} value={this.state.form.phone}
                                     name="phone" id="phone" type="tel" placeholder="(00)00000-0000" mask="(99)99999-9999"
                                     maskChar=" "/>
                        </div>
                        {this.props.property.title &&
                            <div className="rld-single-input">
                      <textarea value={this.state.form.message} style={{
                        padding: 0,
                        margin: 0,
                        background: '#F6F7FB',
                        border: 0,
                        borderBottom: '1px solid rgba(197, 197, 197, 0.5)',
                        overflow: 'hidden'
                      }} name="message" id="message"></textarea>
                            </div>
                        }
                        <div className="btn-wrap text-center">
                          {this.state.sending &&
                              <button className="btn btn-yellow" disabled={true}>Enviando...</button>
                          }
                          {!this.state.sending &&
                              <button className="btn btn-yellow">Enviar Mensagem</button>
                          }
                        </div>
                      </form>
                  }
                </div>
              </div>
            </div>
            {this.props.property.development && this.props.property.development.videos.length > 0 &&
                <div className="property-news-single-card style-two border-bottom-yellow">
                  <h4>Vídeos do Empreendimento</h4>
                  <div className="row">
                    {this.props.property.development.videos.map((video, i) =>
                        <div className="col-sm-6">
                          {video.type == 'upload' &&
                              <video controls controlsList="nodownload" src={video.path}
                                     style={{width: '100%', maxHeight: '300px', backgroundColor: 'black'}}></video>
                          }
                          {video.type == 'youtube' &&
                              <iframe src={video.path} frameborder="0"></iframe>
                          }
                        </div>
                    )}
                  </div>
                </div>
            }
            {/*{this.props.property.development &&*/}
            {/*<div className="property-news-single-card style-two border-bottom-yellow">*/}
            {/*  <h4>Localização Aproximada</h4>*/}
            {/*  <div style={{height: 500, overflow: 'hidden'}}>*/}
            {/*    <MyMapComponent*/}
            {/*      googleMapURL="https://maps.googleapis.com/maps/api/js?&key=AIzaSyBwWT20DUynYX3YfWEwhra73KnUIUImLKY&v=3.exp&libraries=geometry,drawing,places"*/}
            {/*      loadingElement={<div style={{ height: `100%` }} />}*/}
            {/*      containerElement={<div style={{ height: `400px` }} />}*/}
            {/*      mapElement={<div style={{ height: `100%` }} />}*/}
            {/*  />*/}

            {/*  /!*<iframe*!/*/}
            {/*  /!*    src={'https://www.google.com/maps/embed/v1/place?q=' + this.state.latitude +',' + this.state.longitude + '&key=AIzaSyBwWT20DUynYX3YfWEwhra73KnUIUImLKY'}*!/*/}
            {/*  /!*    style={{marginTop: -165, border: 0, height: 500}}*!/*/}
            {/*  /!*    disableDefaultUI={true} />*!/*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*}*/}
            <div className="property-news-single-card border-bottom-yellow pb-3">
              <h4>Fatos e Características</h4>
              <div className="row">
                {this.props.property.development && this.props.property.development.elevator &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={"/assets/img/icons/7.png"} alt={imagealt} loading="lazy"/>
                    </div>
                    <div className="media-body">
                      <h6>Elevadores</h6>
                      <p>{this.props.property.development.elevator}</p>
                    </div>
                  </div>
                </div>
                }
                {this.props.property.development && this.props.property.development.floors &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-building"/>
                    </div>
                    <div className="media-body">
                      <h6>Pavimentos</h6>
                      <p>{this.props.property.development.floors}</p>
                    </div>
                  </div>
                </div>
                }
                {this.props.property.square &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-sun-o"/>
                    </div>
                    <div className="media-body">
                      <h6>Quadramar</h6>
                      <p>{this.props.property.square == 1 ? 'Sim' : 'Não'}</p>
                    </div>
                  </div>
                </div>
                }
                {this.props.property.development && this.props.property.development.front_sea &&
                    <div className="col-md-3 col-sm-6">
                      <div className="single-floor-list media">
                        <div className="media-left">
                          <i className="fa fa-ship"/>
                        </div>
                        <div className="media-body">
                          <h6>Frente Mar</h6>
                          <p>{this.props.property.development.front_sea == 1 ? 'Sim' : 'Não'}</p>
                        </div>
                      </div>
                    </div>
                }

                {this.props.property.development && this.props.property.development.units_per_floor &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-building"/>
                    </div>
                    <div className="media-body">
                      <h6>Unidades por Andar</h6>
                      <p>{this.props.property.development.units_per_floor}</p>
                    </div>
                  </div>
                </div>
                }
                {this.props.property.development && this.props.property.development.construction_year &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-cog"/>
                    </div>
                    <div className="media-body">
                      <h6>Ano de construção</h6>
                      <p>{this.props.property.development.construction_year}</p>
                    </div>
                  </div>
                </div>
                }
                {this.props.property.hydros &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-bath"/>
                    </div>
                    <div className="media-body">
                      <h6>Hidros</h6>
                      <p>{this.props.property.hydros}</p>
                    </div>
                  </div>
                </div>
                }
                {this.props.property.storehouse &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-archive"/>
                    </div>
                    <div className="media-body">
                      <h6>Depósito</h6>
                      <p>{this.props.property.storehouse}</p>
                    </div>
                  </div>
                </div>
                }
                {this.props.property.condo_price &&
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-money"/>
                    </div>
                    <div className="media-body">
                      <h6>Condomínio </h6>
                      <p>{this.props.property.condo_price}</p>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  }
}
export default PropertyDetails
