import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import blogdata from '../../data/blogdata.json';
import OwlCarousel from 'react-owl-carousel';
import axios from "axios";
import ConsoleHelper from "../../helpers/ConsoleHelper";

class PopularPost extends Component {

    state = {
        news: []
    };

    async componentDidMount() {
        await this.loadPopularNews();
    }

    async loadPopularNews() {
        axios.get(process.env.REACT_APP_API_URL + '/v1/news?page=1')
            .then(async (res) => {
                const newsResource = res.data;
                ConsoleHelper(res.data)
                await this.setState({
                    news: newsResource.data
                })
            })
            .catch()
    }

    render() {
        return (
            <div>
                <div className="popular-post-area">
                    <div className="container">
                        <div className="post-and-search">
                            <div className="news-search-btn">
                                <i className="fa fa-search"/>
                            </div>
                            <form className="news-search-box">
                                <input type="text" placeholder="Buscar"/>
                                <button><i className="fa fa-search"/></button>
                            </form>
                            {this.state.news.length > 0 &&
                                <div>
                                    <h6 className="mb-3 popular-post-title">{'Mais populares'}</h6>
                                    <OwlCarousel className="popular-post-slider">
                                        {this.state.news.map((item, i) =>
                                            <div key={i} className="item">
                                                <Link to={'noticia/' + item.slug} className="media single-popular-post">
                                                    <div className="media-body">
                                                        <h6>{item.title}</h6>
                                                        <span>{item.when}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                    </OwlCarousel>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopularPost;
