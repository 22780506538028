import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import sectiondata from '../../data/sections.json';
import axios from "axios";
import ConsoleHelper from "../../helpers/ConsoleHelper";

class Banner extends Component {


  state = {
    types: [],
    cities: [],
    filters: {
      order: 'id,DESC',
      base_screen: 'home',
      name: ''
    }
  }

  componentDidMount() {
    const $ = window.$;
    // if ($('.single-select').length) {
    //     $('.single-select').niceSelect();
    // }
    this.loadCities();
    this.loadTypes();
  }

  loadCities() {
    axios.get(process.env.REACT_APP_API_URL + '/v1/cities')
        .then(res => {
          const cities = res.data;
          ConsoleHelper(cities)
          this.setState({cities: cities})
        })
        .catch(error => {
          ConsoleHelper(error)
        })
  }

  loadTypes() {
    axios.get(process.env.REACT_APP_API_URL + '/v1/type')
        .then(res => {
          const types = res.data;
          ConsoleHelper(types)
          this.setState({types: types})
        })
        .catch(error => {
          ConsoleHelper(error)
        })
  }

  encodeGetParams = p =>
      Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

  handleInputChange = e => {
    this.setState({
      filters: {...this.state.filters, [e.target.name]: e.target.value}
    });
    ConsoleHelper(this.state.filters)
  }

  render() {
    let imagealt = 'image'
    let data = sectiondata.banner

    const inlineStyle = {
      backgroundImage: 'url(/assets/img/banner/bannerbcsmall.webp)'
    }

    return <div className="banner-area jarallax" style={inlineStyle}>
      <div className="container">
        <div className="banner-inner-wrap">
          <div className="row">
            <div className="col-12">
              <div className="banner-inner align-content-center">
                <h5 className="sub-title text-center"
                    style={{marginBottom: '0px', marginTop: '22px'}}>{data.subtitle}</h5>
                <h1 className="title text-center text-orange">{data.title1} <br/> {data.title2}</h1>
              </div>
            </div>
            <div className="col-12">
              <div className="banner-search-wrap">
                <ul className="nav nav-tabs rld-banner-tab">
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tabs_1">Para Compra</a>
                  </li>
                  <li className="nav-item">
                    <Link to={"/agenciamento"} className="nav-link active">Cadastre seu imóvel</Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="tabs_1">
                    <div className="rld-main-search">
                      <div className="row">
                        <div className="col-xl-2 col-lg-4 col-md-4">
                          <div className="rld-single-input left-icon">
                            <input onChange={this.handleInputChange} type="text"  placeholder="Código" name="name"/>
                          </div>
                        </div>

                        {(this.state.cities.length > 0 &&
                            <div className="col-xl-2 col-lg-6 col-md-6">
                              <div className="rld-single-select">
                                <select className="select single-select" name="city" onChange={this.handleInputChange}>
                                  <option key={'all'} value={''}>Cidades</option>
                                  {this.state.cities.map((city, i) =>
                                      <option key={i}
                                              value={city.city}>{city.city}</option>
                                  )}
                                </select>
                              </div>
                            </div>
                        )}
                        {(this.state.types.length > 0 &&
                            <div className="col-xl-2 col-lg-6 col-md-6">
                              <div className="rld-single-select">
                                <select className="select single-select" name="type" onChange={this.handleInputChange}>
                                  <option key={'all'} value={''}>Tipos</option>
                                  {this.state.types.map((type, i) =>
                                      <option key={i}
                                              value={type}>{type}</option>
                                  )}
                                </select>
                              </div>
                            </div>
                        )}
                        <div className="col-xl-2 col-lg-4 col-md-4">
                          <div className="rld-single-select">
                            <select className="select single-select" name="suites_from"
                                    onChange={this.handleInputChange}>
                              <option key={0} value={''}>Suítes</option>
                              <option key={1} value={0}>0+</option>
                              <option key={2} value={1}>1+</option>
                              <option key={3} value={2}>2+</option>
                              <option key={4} value={3}>3+</option>
                              <option key={5} value={4}>4+</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4">
                          <div className="rld-single-select">
                            <select className="select single-select" name="price_from"
                                    onChange={this.handleInputChange}>
                              <option value={''}>Preço</option>
                              <option value={'300000'}>300.000+</option>
                              <option value={'700000'}>700.000+</option>
                              <option value={'1000000'}>1.000.000+</option>
                              <option value={'2000000'}>2.000.000+</option>
                              <option value={'3000000'}>3.000.000+</option>
                              <option value={'4000000'}>4.000.000+</option>
                              <option value={'5000000'}>5.000.000+</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-4 col-md-4 readeal-top">
                          <Link className="btn btn-yellow" to={{
                            pathname: '/search-list',
                            search: this.encodeGetParams(this.state.filters)
                          }}>BUSCAR</Link>
                          <Link to="/search-list" style={{textDecoration: 'underline', float: 'right'}}>Busca avançada<i className={'fa fa-chevron-down'}></i></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  }
}

export default Banner
