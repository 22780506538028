import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Service from './section-components/service';
import Explore from './section-components/explore';
import FeaturedProperties from './section-components/featured-properties';
import PropertiesByCities from './section-components/properties-by-cities';
import RecentProperties from './section-components/recent-properties';
import WhyChooseUs from './section-components/why-choose-us';
import Footer from './global-components/footer';
import TeamV2 from './section-components/team-v2';
import FooterFixed from "./global-components/footer-fixed";

const Home_V1 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Service />
        <FeaturedProperties />
        <Explore />
        {/*<PropertiesByCities />*/}
        {/*<RecentProperties />*/}
        <WhyChooseUs />
        <TeamV2 />
        <Footer />
        <FooterFixed />
    </div>
}

export default Home_V1

