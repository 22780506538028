import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Mission from './section-components/mission';
import AboutUs from './section-components/about-us';
import AddNew from "./section-components/add-new";
import Footer from "./global-components/footer";
import FooterFixed from "./global-components/footer-fixed";

const About = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Sobre" />
        <Mission />
        <AboutUs />
        <Footer />
        <FooterFixed />
    </div>
}

export default About

