import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import {Link} from 'react-router-dom';
import axios from "axios/index";
import ConsoleHelper from "../../helpers/ConsoleHelper";
import MetaTags from 'react-meta-tags';
import LogAction from "../../helpers/LogAction";

class Property extends Component {

    state = {
        properties: []
    };

    componentDidMount() {
        axios.get(process.env.REACT_APP_API_URL + '/v1/property?order=destaque,ASC&destaque_from=1')
            .then(res => {
                const properties = res.data.data;
                ConsoleHelper(properties)
                this.setState({properties: properties})
            })
    }

    linkUrl(property) {
        return encodeURI('https://portalbc.online/imovel/' +  property.slug);
    }

    getTotalDormitorios(property) {
        let total = 0;
        total = property.suites ? parseInt(property.suites) : 0;
        total += property.demi_suites ? parseInt(property.demi_suites) : 0;
        total += property.bedrooms ? parseInt(property.bedrooms) : 0;
        return total;
    }

    getTotalBanheiros(property) {
        let total = 0;
        total = property.suites ? parseInt(property.suites) : 0;
        total += property.social_baths ? parseInt(property.social_baths) : 0;
        total += property.washrooms ? parseInt(property.washrooms) : 0;
        total += property.service_bathrooms ? parseInt(property.service_bathrooms) : 0;
        return total;
    }


    render() {
        let imagealt = 'image'
        let data = sectiondata.propertybtcities


        return <div className="properties-area pd-top-92">
            <div className="container">
                <div className="row">

                    {this.state.properties.map((item, i) =>
                        <div key={i} className="col-lg-4 col-sm-6 ">
                            <MetaTags>
                                <title>Imóveis Adicionados Recentemente</title>
                                <link rel="canonical" href={document.location.href}/>
                                <meta name="description" content="Lista de imóveis adicionados recentemente, entre casas, apartamento, apartamento diferenciado, apartamento decorado, coberturas e imóveis para compra em Balneário Camboriú, Itapema, Meia Praia, Porto Belo, Porto Alegre"/>
                                <meta property="og:title" content="Imóveis Adicionados Recentemente"/>
                                <meta property="og:image" content="/assets/img/logo.png" content="image/jpeg"/>
                                <meta property="og:url" content={document.location.href} />
                                <meta property="og:site_name" content="Portal BC" />
                            </MetaTags>
                            <div className="single-feature">
                                <div className="thumb" style={{height: '255px'}}>
                                    <img src={item.cover} alt="img" loading="lazy" style={{width:'100%', height: '100%'}}/>
                                </div>
                                <div className="details">
                                    {/*<a href="#" className="feature-logo">*/}
                                    {/*<img src={ item.icon } alt={ imagealt } />*/}
                                    {/*</a>*/}
                                    <div className="row" style={{minHeight: '54px'}}>
                                        <div className="col-10">
                                            <p className="author"><i className="fa fa-building"/> {item.development.name}</p>
                                        </div>
                                        <div className="col-2">
                                            <p className="author"> {item.id}</p>
                                        </div>
                                    </div>
                                    <h6 className="title readeal-top" style={{minHeight: '59px'}}>
                                        <a onClick={e => LogAction('property_click', 'destaque', {property_id: item.id})} href={'https://portalbc.online/imoveis/' +item.slug} target="_blank">{item.development.name} - {item.development.city} - COD {item.id}</a>
                                    </h6>
                                    <ul className="info-list" style={{borderTop:'none'}}>
                                        {this.getTotalDormitorios(item) &&
                                            <li><i className="fa fa-bed"></i> {this.getTotalDormitorios(item)}</li>
                                        }
                                        {this.getTotalBanheiros(item) &&
                                            <li><i className="fa fa-bath"></i> {this.getTotalBanheiros(item)}</li>
                                        }
                                        {item.parking_lots &&
                                            <li><i className="fa fa-car"></i> {item.parking_lots}</li>
                                        }
                                        {item.useful_area &&
                                            <li><img src={"/assets/img/icons/7.png"} alt={imagealt} loading="lazy"/> {item.useful_area}m²</li>
                                        }
                                    </ul>
                                    <div className="info-list">
                                        <h6 className="price">{item.price}</h6>{/*<del>{ item.olderprice }</del>*/}
                                    </div>
                                    <ul className="contact-list">
                                        <li><a className="phone" target={"_blank"}
                                               href={encodeURI('https://api.whatsapp.com/send?phone=+554733111100&text=Olá, tenho interesse no imóvel: ' + this.linkUrl(item))}><i
                                            className="fa fa-whatsapp"/></a></li>
                                        {/*<li><a className="message" href="#"><img src={ "/assets/img/icons/8.png" } alt="img" /></a></li>*/}
                                        <li className="readeal-top">
                                            <a onClick={e => LogAction('property_click', 'destaque', {property_id: item.id})} className="btn btn-yellow" href={'https://portalbc.online/imoveis/' +item.slug} target="_blank">Ver
                                                Detalhes
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>


    }
}

export default Property
