import React, { Component } from 'react';
import axios from "axios/index";
import ConsoleHelper from "../../helpers/ConsoleHelper";
import {FacebookShareButton, WhatsappShareButton} from "react-share";
import MetaTags from 'react-meta-tags';
class NewsDetails extends Component {

  state = {
    post: null
  }

  async componentDidMount() {
    axios.get(process.env.REACT_APP_API_URL + '/v1/news/' + this.props.id)
        .then(async (res) => {
          await this.setState({
            post: res.data
          });
        })
        .catch((error) => {
          ConsoleHelper(error);
        });
  }

  buttonShareStyles(type) {
    let color = 'auto';
    switch (type) {
      case 'whatsapp':
        color = '#075E54';
        break;
      case 'facebook':
        color = '#3b5998';
        break;
    }
    return {
      backgroundColor: 'white',
      color: color,
      height: '60px',
      width: '60px',
      borderRadius: '4px',
      lineHeight: '58px',
      fontSize: '20px',
      border: '0',
      boxShadow: '0px 20px 40px rgb(0 0 0 / 12%)'
    }
  }

  postUrl() {
    return document.location.href;
  }

  render() {

    return (
      <div>
        {this.state.post &&
          <div className="news-details-area">
            <MetaTags>
              <title>{this.state.post.title}</title>
              <link rel="canonical" href={this.postUrl()}/>
              <meta name="description" content={this.state.post.title}/>
              <meta property="og:title" content={this.state.post.title}/>
              <meta property="og:image" content={!this.state.post.cover ? '/assets/img/news/1.jpg' : this.state.post.cover} content="image/jpeg"/>
              <meta property="og:url" content={this.postUrl()} />
              <meta property="og:site_name" content="Portal BC" />
            </MetaTags>
            <div className="container">
              <div className="news-details-author-social">
                <div className="row">
                  <div className="col-sm-6 mb-4 mg-sm-0">
                    <div className="author">
                      <img src={ "/assets/img/logo.png" } alt="news" />
                      <p>por Portal BC</p>
                      <p>{ this.state.post.when }</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <ul className="social-icon style-two text-sm-right">
                      <li>
                        <div style={{
                          color: '#212529b3',
                          height: '60px',
                          verticalAlign: 'top',
                          fontWeight: 'bold',
                          paddingTop: '27px'
                        }}>Compartilhar
                        </div>
                      </li>
                      <li key={1}>
                        <WhatsappShareButton url={this.postUrl()} style={this.buttonShareStyles('whatsapp')} title={'Confira a notícia ' + this.state.post.title + ' em '} className={'facebook'}>
                          <i className="fa fa-whatsapp"></i>
                        </WhatsappShareButton>
                      </li>
                      <li key={2}>
                        <FacebookShareButton url={this.postUrl()} style={this.buttonShareStyles('facebook')} title={'Confira a notícia ' + this.state.post.title + ' em ' + this.postUrl()} className={'facebook'}>
                          <i className="fa fa-facebook"></i>
                        </FacebookShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center pd-top-70">
                <div className="col-lg-8">
                  <div className="news-details-wrap">
                    <h3 className="title1">{ this.state.post.title }</h3>
                    <div dangerouslySetInnerHTML={{__html: this.state.post.text}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default NewsDetails;
