import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';
import axios from "axios/index";
import ConsoleHelper from "../../helpers/ConsoleHelper";

class Property extends Component {

    state = {
        data: sectiondata.explore,
        properties: {}
    }

    async componentDidMount() {
        await this.loadByLocalization();
    }

    async loadByLocalization() {
        for (let i = 0; i < this.state.data.items.length; i++) {
            let filter = this.state.data.items[i].url.replace('/search-list', '');
            await axios.get(process.env.REACT_APP_API_URL + '/v1/property' + filter)
                .then(async (properties) => {
                    this.state.data.items[i].properties = properties.data.data.slice(0, 4);
                    const propertiesUpdated = this.state.data;
                    ConsoleHelper(propertiesUpdated)
                    await this.setState({
                        data: propertiesUpdated
                    })
                })
                .catch((error) => {
                    ConsoleHelper(error);
                })
        }
    }

      linkUrl(property) {
        return encodeURI(process.env.REACT_APP_SHARE_URL + '/imovel/' + property.slug);
      }


    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imagealt = 'image'


        return <div className="properties-area pd-top-92">
            <div className="container">

                    {this.state.data.items && this.state.data.items.map((item, i) =>
                        <div key={i}>
                            <div className="section-title">
                                <h2 className="title">{item.city}</h2>
                            </div>
                            <div className="row">
                                {item.properties.length > 0 && item.properties.map((property, c) =>
                                    <div key={c} className="col-lg-3 col-sm-6 ">
                                        <div className="single-feature">
                                            <div className="thumb">
                                                <img src={property.cover} alt="img"/>
                                            </div>
                                            <div className="details">
                                                {property.company &&
                                                    <div>
                                                        <a href="#" className="feature-logo">
                                                            <img src={property.company.icon} alt={imagealt}/>
                                                        </a>
                                                        <p className="author"><i className="fa fa-user"/> {property.company.name}</p>
                                                    </div>
                                                }
                                                <h6 className="title readeal-top">
                                                    <a href={'https://portalbc.online/imoveis/' +property.slug}>
                                                        {property.title}
                                                    </a>
                                                </h6>
                                                <h6 className="price">{property.price}</h6>
                                                <ul className="info-list">
                                                    <li><img src={publicUrl + "/assets/img/icons/7.png"}
                                                             alt={imagealt}/> {property.area}  </li>
                                                </ul>
                                                <ul className="contact-list">
                                                  <li><a className="phone" target={"_blank"}
                                                         href={encodeURI('https://api.whatsapp.com/send?phone=+554733111100&text=Olá, tenho interesse no imóvel: ' + this.linkUrl(item))}><i
                                                      className="fa fa-whatsapp"/></a></li>
                                                    <li className="readeal-top">
                                                        <a className="btn btn-yellow" target={"_blank"} href={'https://portalbc.online/imoveis/' +property.slug}>
                                                            Ver Detalhes
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

            </div>
        </div>


    }
}

export default Property
