import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import axios from "axios/index";

class PostList extends Component {

    state = {
        currentPage: 0,
        lastPage: 1,
        news: []
    };

    componentDidMount() {
        this.loadNews();
    }

    async loadNews( clear = false) {
        if (!clear) {
            const page = this.state.currentPage++;
            await this.setState({
                currentPage: page
            });
        } else {
            await this.setState({
                currentPage: 1
            });
        }
        axios.get(process.env.REACT_APP_API_URL + '/v1/news?page=' + this.state.currentPage)
            .then((res) => {
                const newsResource = res.data;
                const newsList = this.state.news;
                newsResource.data.map((newsItem) => {
                    newsList.push(newsItem);
                })
                this.setState({
                    news: newsList,
                    currentPage: newsResource.meta.current_page,
                    lastPage: newsResource.meta.last_page
                })
            })
            .catch()
    }

    render() {
        return (
            <div>
                <div className="property-news-area pd-top-100 pd-bottom-70">
                    <div className="container">
                        <div className="row">
                            {this.state.news.map((item, i) =>
                                <div key={i} className="col-lg-6">
                                    <div className="single-news">
                                        <div className="thumb">
                                            <img src={!item.cover ? '/assets/img/news/1.jpg' : item.cover} alt={item.title}/>
                                        </div>
                                        <div className="details">
                                            <h4><Link to={"noticia/" + item.slug}>{item.title}</Link></h4>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PostList;
