import React, {Component} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsSection from './section-components/property-details';
import RecomandProperties from './section-components/recomand-properties';
import Footer from './global-components/footer';
import axios from "axios/index";
import ConsoleHelper from "../helpers/ConsoleHelper";
import MetaTags from 'react-meta-tags';
import redirect from "react-router-dom/es/Redirect";
import FooterFixed from "./global-components/footer-fixed";

// const PropertyDetails = (props) => {
// }

class PropertyDetails extends Component {

    state = {
        property: {
            title: '...'
        },
        loading: false,
        extraFooter: <div></div>
    };

    async componentDidMount() {
        await this.loadProperty();
    }

    async loadProperty() {
        await this.setState({
            loading: true
        });
        await axios.get(process.env.REACT_APP_API_URL + '/v1/property/' + this.props.id)
            .then(async res => {
                const property = res.data;
                await this.setState({
                    property: property,
                    loading: false,
                    extraFooter: this.footerFixed(property)
                });

                window.scrollTo(0, 0);
            })
            .catch(async error => {
                ConsoleHelper(error)
                await this.setState({
                    loading: false
                });
                window.location = '/#/search-list'
            })
    }

    async componentDidUpdate(prevProps) {
        ConsoleHelper(prevProps,this.props);
        if (prevProps.id !== this.props.id) {
            await this.setState({
                type: this.props.id,
            });
            await this.loadProperty();
        }
    }

    footerFixed(property) {
        ConsoleHelper( property);
        return <FooterFixed property={property}/>;
    }

    render() {
        return <div>
            <MetaTags>
                <title>{this.state.property.title}</title>
                <link rel="canonical" href={process.env.PUBLIC_URL + '/imovel/' + this.state.property.slug}/>
                <meta name="description" content={this.state.property.description}/>
                <meta name="og:description" content={this.state.property.description}/>
                <meta property="og:title" content={this.state.property.title}/>
                <meta property="og:image" content={this.state.property.internalPhotos ? this.state.property.internalPhotos[0].path.split('.webp')[0] : this.state.property.cover}/>
                <meta property="og:url" content={process.env.PUBLIC_URL + '/imovel/' + this.state.property.slug} />
                <meta property="og:type" content="article" />
                <meta property="og:updated_time" content={this.state.property.updated_at} />
                <meta property="og:site_name" content="Portal BC" />
                <meta property="og:locale" content="pt_BR" />
            </MetaTags>
            <Navbar/>
            <PageHeader property={this.state.property} headertitle={this.state.property.title}/>
            {!this.state.loading &&
                <div>
                    <PropertyDetailsSection property={this.state.property}/>
                    <RecomandProperties property_id={this.props.id}/>
                </div>
            }
            <Footer/>
            {!this.state.loading && this.state.extraFooter}
        </div>
    }
}

export default PropertyDetails

