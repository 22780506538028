import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";

import HomeV1 from './components/home-v1';
import Property from './components/property';
import PropertiesByCity from './components/properties-by-city';
import RecentProperties from './components/recent-properties';
import PropertyDetails from './components/property-details';
import About from './components/about';
import Error from './components/error';
import News from './components/news';
import NewsDetails from './components/news-details';
import Contact from './components/contact';
import SearchList from './components/search-list';
import ScrollToTop from "./helpers/scrollToTop";
import Terms from "./components/terms";
import Partners from "./components/partners";
import Agency from "./components/agency";
import HighlightProperties from "./components/highlight-properties";
require('dotenv').config()

class Root extends Component {
    render() {
        return(
            <Router>
                <ScrollToTop />
                <HashRouter basename="/">
                <div>
                    <Switch>
                        <Route exact path="/" component={HomeV1}/>
                        <Route path="/property" component={Property}/>
                        <Route path="/properties-by-city" component={PropertiesByCity}/>
                        <Route path="/imoveis-recentes" component={RecentProperties}/>
                        <Route path="/property-details/:id"
                               component={(routerProps) => <PropertyDetails id={routerProps.match.params.id}/>}/>
                        <Route path="/imovel/:slug" component={(routerProps) => <PropertyDetails
                            id={routerProps.match.params.slug.split('-').slice(-1)[0]}/>}/>
                        <Route path="/imoveis/:slug" component={(routerProps) => <PropertyDetails
                            id={routerProps.match.params.slug.split('-').slice(-1)[0]}/>}/>
                        <Route path="/sobre" component={About}/>
                        <Route path="/noticias" component={News}/>
                        <Route path="/noticia/:slug" component={(routerProps) => <NewsDetails
                            id={routerProps.match.params.slug.split('-').slice(-1)[0]}/>}/>
                        <Route path="/contato" component={Contact}/>
                        <Route path="/search-list" component={SearchList}/>
                        <Route path="/politica-privacidade" component={Terms}/>
                        <Route path="/parceiros" component={Partners}/>
                        <Route path="/agenciamento" component={Agency}/>
                        <Route path="/destaque" component={HighlightProperties}/>
                        <Route component={Error}/>
                    </Switch>
                </div>
                </HashRouter>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('realdeal'));
