import React, {Component} from 'react';
import sectiondata from '../../data/sections.json';
import MetaTags from 'react-meta-tags';

class AboutUs extends Component {

  render() {

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'
    let data = sectiondata.aboutus


    return <div className="about-area pd-bottom-90">
      <div className="container">
        <MetaTags>
          <title>{data.subtitle}</title>
          <link rel="canonical" href={document.location.href}/>
          <meta name="description" content={data.title}/>
          <meta property="og:title" content={data.subtitle}/>
          <meta property="og:image" content={publicUrl + data.image} content="image/jpeg"/>
          <meta property="og:url" content={document.location.href} />
          <meta property="og:site_name" content="Portal BC" />
        </MetaTags>
        <div className="row">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="shape-image-list-wrap">
              <div className="shape-image-list left-top">
                <img className="shadow-img" src={publicUrl + data.image} alt={imagealt}/>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="section-title pd-left mb-0">
              <h5 className="sub-title">{data.subtitle}</h5>
              <h2 className="title">{data.title}</h2>
              <p>O <strong> PortalBC </strong> apresenta um novo aproach no setor imobiliário.
                Hoje, as comunicações são fluidas, rápidas e detalhadas. Apesar da quarentena, mas com a internet na
                “palma das mãos”, eliminamos as distâncias reais e a informação deve estar sempre, completamente
                disponível e imediatamente. Gostamos de trabalhar com o novo, com a técnica, com as qualificações
                máximas do setor, e, nesses 20 anos de mercado imobiliário, acumulamos muito.</p>
              {/*<a className="btn btn-yellow" href={data.url}>{data.btn_text}</a>*/}
            </div>
          </div>
        </div>
      </div>
    </div>

  }
}

export default AboutUs
