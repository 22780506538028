import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import TermsSection from './section-components/terms';
import Footer from './global-components/footer';
import FooterFixed from "./global-components/footer-fixed";

const Terms = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Política de Privacidade" />
        <TermsSection />
        <Footer />
        <FooterFixed />
    </div>
}

export default Terms

