import React, {Component} from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import axios from "axios";
import ConsoleHelper from "../helpers/ConsoleHelper";
import InputMask from "react-input-mask";
import MetaTags from 'react-meta-tags';
import ReCAPTCHA from "react-google-recaptcha";
import FooterFixed from "./global-components/footer-fixed";
const recaptchaRef = React.createRef();
class Contact extends Component {


    state = {
        messageSent: false,
        form: {},
        errors: {},
        sending: false
    }

    sendContact = async e => {
        e.preventDefault();
        this.setState({sending: true, errors: {}});

        let data = this.state.form;
        data.recaptcha = recaptchaRef.current.getValue();
        axios.post(process.env.REACT_APP_API_URL + '/v1/contact', data)
            .then(res => {
                this.setState({sending: false});
                const success = res.data;
                ConsoleHelper(success)
                this.setState({messageSent: true})
            })
            .catch((error) => {
                this.setState({sending: false});
                ConsoleHelper(error, error.response)
                const data = error.response.data;
                if (typeof data.errors != 'undefined') {
                    this.setState({errors: data.errors})
                }
            });
    }

    handleInputChange = e => {
        this.setState({
            form: {...this.state.form, [e.target.name]: e.target.value}
        });
    }

    render() {
        return <div>
          <MetaTags>
            <title>Contato</title>
            <link rel="canonical" href={document.location.href}/>
            <meta name="description" content="Oferecemos diversos canais de comunicação. Converse com a gente sempre que precisar."/>
            <meta property="og:title" content="Contato"/>
            <meta property="og:image" content="/assets/img/logo.png" content="image/jpeg"/>
            <meta property="og:url" content={document.location.href} />
            <meta property="og:site_name" content="Portal BC" />
          </MetaTags>
            <Navbar/>
            <PageHeader headertitle="Contato"/>
            <div className="contact-area pd-top-100 pd-bottom-65">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="contact-page-map">
                                <iframe className="w-100"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3555.936151469215!2d-48.6410920849538!3d-26.968921183103305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8cb16c0443b4b%3A0xf2a844e2e1d286fe!2sRua%20Miguel%20Matte%2C%20687%20-%20Pioneiros%2C%20Balne%C3%A1rio%20Cambori%C3%BA%20-%20SC%2C%2088331-030!5e0!3m2!1spt-BR!2sbr!4v1623764159969!5m2!1spt-BR!2sbr"
                                        style={{
                                            border: 0
                                        }
                                        } allowFullScreen/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            {this.state.messageSent &&
                                <p>Mensagem enviada com sucesso!</p>
                            }
                            {!this.state.messageSent &&
                                <form onSubmit={this.sendContact} className="contact-form-wrap contact-form-bg">
                                <h4>Contato</h4>
                                <div className="rld-single-input">
                                    <input type="text" placeholder="Nome" onChange={this.handleInputChange} value={this.state.form.name} id="name" name="name"/>
                                    { this.state.errors.name &&
                                    <div className="label label-danger">{this.state.errors.name}</div>
                                    }
                                </div>
                                <div className="rld-single-input">
                                    <InputMask {...this.props} onChange={this.handleInputChange} value={this.state.form.phone} name="phone" id="phone" type="tel" placeholder="(00)00000-0000" mask="(99)99999-9999" maskChar=" " />
                                    { this.state.errors.phone &&
                                    <div className="label label-danger">{this.state.errors.phone}</div>
                                    }
                                </div>
                                <div className="rld-single-input">
                                    <input type="email" placeholder="E-mail" onChange={this.handleInputChange} value={this.state.form.email} id="email" name="email"/>
                                    { this.state.errors.email &&
                                    <div className="label label-danger">{this.state.errors.email}</div>
                                    }
                                </div>
                                <div className="rld-single-input">
                                    <textarea rows={10} placeholder="Mensagem" defaultValue={""} onChange={this.handleInputChange} value={this.state.form.message} id="message" name="message"/>
                                    { this.state.errors.message &&
                                    <div className="label label-danger">{this.state.errors.message}</div>
                                    }
                                </div>
                                  <ReCAPTCHA
                                      sitekey="6LdEujgcAAAAAHJsWaMZTT3kEHKzOcogfG0i-97w"
                                      onChange={onChange}
                                      ref={recaptchaRef}
                                  />
                                  { this.state.errors.recaptcha &&
                                <div className="label label-danger">{this.state.errors.recaptcha}</div>
                                }
                                <div className="btn-wrap text-center">
                                    {this.state.sending &&
                                    <button className="btn btn-yellow" disabled={true}>Enviando...</button>
                                    }
                                    {!this.state.sending &&
                                    <button className="btn btn-yellow">Enviar</button>
                                    }
                                </div>
                            </form>
                            }
                        </div>
                    </div>
                    <div className="row pd-top-92">
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-contact-info">
                                <p><i className="fa fa-phone"/>Ligue para PortalBC:</p>
                                <h5>(47) 3311-1100</h5>
                                <h5>(47) 99109-9997</h5>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-contact-info">
                                <p><i className="fa fa-envelope"/>Tem alguma pergunta?</p>
                                <h5>comercial@portalbc.online</h5>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6">
                            <div className="single-contact-info">
                                <p><i className="fa fa-phone"/>Endereço</p>
                                <h5>Rua Miguel Matte, 687 sala 2007 - Bairro pioneiros</h5>
                                <h5>Balneário Camboriú - SC</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <FooterFixed />
        </div>
    }
}
function onChange(value) {
  console.log("Captcha value:", value);
}

export default Contact

