import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer';
import MetaTags from "react-meta-tags";
import FooterFixed from "./global-components/footer-fixed";

const Partners = (props) => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Nossos Parceiros" subheader="Parceiros" />
        <div className="container">

            <div className="text-center">
                <img src="https://www.vinhosevinhos.com/skin/frontend/sm_lovefashion/default/images/vinhos.jpg" alt="" />
            </div>
            <p className="chamada text-justify">
                Desfrute da parceria com a vinhos e vinhos e aproveite os melhores rótulos de vinhos brasileiros:
            </p>
            <p className="chamada text-justify">
                Escreva com vinho, revise com café:&nbsp;
                <a href="https://www.vinhosevinhos.com/vinhos.html"
                   target="_blank"><strong>https://www.vinhosevinhos.com/vinhos.html</strong></a>
            </p>
            <p className="chamada text-justify">
                Relaxe do calor, abra um espumante gelado:&nbsp;
                <a href="https://www.vinhosevinhos.com/espumantes.html"
                   target="_blank"><strong>https://www.vinhosevinhos.com/espumantes.html</strong></a>
            </p>
            <p className="chamada text-justify">
                A maior loja de vinhos brasileiros, acesse esse novo mundo de paladares com o
                cupom <strong>"negociofechado5"</strong>.
            </p>
            <div className="text-center">
                <a href="https://www.vinhosevinhos.com/" target="_blank" className="btn btn-success text-center">SAIBA MAIS</a>
            </div>
            <br/>
            <br/>
        </div>
        <Footer />
        <FooterFixed />
    </div>
}

export default Partners
